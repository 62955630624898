.errorpage-popup {
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    justify-content: center;
    align-items: center;
    bottom: -10%;
    height: 10%;
    opacity: 100;
    text-align: center;
    background-color: red;
    color: white;
    display: flex;
    width: 100vw;
    visibility: hidden;
    z-index: 100001;
    transition: bottom 1s ease-in-out;
}
.errorpage-popup-enabled {
    visibility: visible;
    bottom: 0 !important;
}
.errorpage {
    transition: opacity 1s ease-in-out;
    display: block;
    opacity: 0;
}
.errorpage-icon {
    font-size: 30px;
    margin-right: 10px;
}
.errorpage-enabled {
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 75% !important;
    position: fixed;
    z-index: 100000;
}