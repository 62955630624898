.searchInputCategory {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-radius: 10px;
  z-index: 2;
  border: 2px solid rgb(209, 209, 209);
}

.categorieënBox {
  position: relative; 
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
}

.inputFieldCategory {
  position: sticky; 
  border-radius: 10px;
  top: 0;
  z-index: 10; /* Make sure this is above the product list */
  background: #ffffff; /* To cover any content scrolling beneath it */
}

.searchInput {
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  padding: 5px 10px;
  border-radius: 30px;
}

.searchInput:focus {
  outline: white;
}

.categoryList {
  max-height: 250px;
  overflow-y: auto; /* To make this part scrollable */
}

.categoryCheckbox input[type="checkbox"] {
  display: none;
}

.categoryCheckbox label {
  position: relative;
  padding-left: 30px;
}

.categoryCheckbox label:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  top: 1px;
  left: 1px;
  background-color: white;
}

.categoryCheckbox input[type="checkbox"]:checked + label:before {
  background-color: #009fe3;
}

.categoryCheckbox input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  border-width: 0 0 2px 2px;
  border-style: solid;
  border-color: white;
  height: 8px;
  width: 12px;
  transform: rotate(-45deg);
}

.subcategoryItem {
  position: relative;
  padding-left: 22px; /* This padding pushes the text to align under the checkbox of the parent */
}

.subcategoryUL {
  padding-left: 0;
}

/* .branchIndicator {
  position: absolute;
  left: 10px; 
  top: 8px; 
  transform: translateY(-50%); 
  height: 11px; 
  width: 17px; 
  border-left: 2px solid #cfcfcf; 
  border-bottom: 2px solid #cfcfcf; 
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
} */

.categoryCheckbox {
  padding: 2px 0px; /* Override this for subcategories if it has a value */
}