@import url(https://fonts.googleapis.com/css?family=Nunito);

:root {
    /* techdog colors */
    --GradientBlue: linear-gradient(180deg, rgba(0,159,227,1) 0%, rgba(2,90,122,1) 100%);
    --LightBlue: #E2EEFB;
    --Blue: #009FE3;
    --DarkBlue: #025A7A;
    --Black: #000000;
    --Red: #FF0000;
    --Orange: #FF8800;
    --Green: #3fc57c;
    --DarkGrey: #6A6A6A;
    --LightGrey: #E3E3E3;
    --LightRed: #FFB7B7;
    --LightOrange: #FFF8E5;
    --LightGreen: #E3FFF1;
    --White: #FFFFFF;

    /* Hover Colors */
    --LightBlueHover: #b5d4f5;
    --BlueHover: #007bb0;
    --DarkBlueHover: #013548;
    --BlackHover: #1a1a1a;
    --RedHover: #cc0000;
    --OrangeHover: #cc6d00;
    --GreenHover: #30a163;
    --DarkGreyHover: #505050;
    --LightGreyHover: #c9c9c9;
    --LightRedHover: #ff8484;
    --LightOrangeHover: #ffeab2;
    --LightGreenHover: #b0ffd7;
    --WhiteHover: #e6e6e6;

    /* Disabled Colors */
    --LightBlueDisabled: #b5d4f5;
    --BlueDisabled: #007bb0;
    --DarkBlueDisabled: #013548;
    --BlackDisabled: #1a1a1a;
    --RedDisabled: #cc0000;
    --OrangeDisabled: #cc6d00;
    --GreenDisabled: #30a163;
    --DarkGreyDisabled: #505050;
    --LightGreyDisabled: #c9c9c9;
    --LightRedDisabled: #ff8484;
    --LightOrangeDisabled: #ffeab2;
    --LightGreenDisabled: #b0ffd7;
    --WhiteDisabled: #e6e6e6;

    /* Text Colors */

    --TextColorWhite: #FFFFFF;
    --TextColorBlack: #000000;
    --TextColorLink: #009FE3;


    --FontFamily: "Nunito";
    --FontWeightBold: bold;
    --FontWeightMedium: medium;
    --FontWeightRegular: regular;

    --ButtonHeight: 45px;
    --ButtonIconGap: 20px;

    /* button gap */
    --ButtonTextIconGap: 20px;

    /* HUB Title */
    --HubTitleFontSize: 45px;
    /* Block Title */
    --BlockTitleFontSize: 25px;
    /* Table Title */
    --TableTitleFontSize: 20px;
    /* Button text */
    --ButtonTextFontSize: 16px;
    /* Flat text link */
    --FlatTextLinkFontSize: 16px;
    /* Flat text */
    --FlatTextFontSize: 16px;
    /* Text beneath Flat text */
    --BeneathFlatTextFontSize: 14px;

    /* Border Thickness */
    --RegularBorderThickness: 1px;
    --SecondBorderThickness: 2px;

    /* Border Radiusses */
    --RegularBorderRadius: 5px;
    --SecondBorderRadius: 200px;

    --SearchBarMinimumWidth: 400px;
}

/* Scrollbar */

::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--Blue);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--BlueHover);
}

body {
    background-color: #e2eefb !important;
    height: 100vh;
    font-family: var(--FontFamily), sans-serif;
}

label, h1, h2, h3, h4, h5, h6, a, p {
    font-family: 'Nunito';
}

.whiteBox {
    background-color: white;
    border-radius: 15px;
    padding: 10px;
}


.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #fff;
    border: 1px solid white;
    border-bottom: 4px solid #009fe3;
    position: relative;
    top: 3px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid rgb(255, 255, 255);
    margin: 2px;
    margin-bottom: 0px;
    /* margin-left: 15px;
    margin-right: 15px; */
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    top: 3px;
    min-width: fit-content; 
    white-space: nowrap;
    width: fit-content; 
}

.nav-tabs > li:first-child > .nav-link {
    margin-left: 0px !important;
}

.nav-tabs > li:last-child > .nav-link {
    margin-right: 0px !important;
}

ul.nav.nav-tabs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding-bottom: 0px;
    margin-right: 0px !important;
    background-color: #ffffff;
    border-bottom: 2px solid #b8b8b8;
    gap: 0px; /* Voeg ruimte tussen de tabs toe */
    justify-content: flex-start; /* Plaats tabs links zonder grote ruimte */
  }
  
  ul.nav.nav-tabs li {
    flex: 1 1 auto; /* Verdeel tabs gelijkmatig over de rij */
  }

.nav-tabs .nav-link:hover {
  border-bottom: none;  /* Change to your preferred border color */
  position: relative;
  border: 1px solid rgb(255, 255, 255);
  border-bottom: 4px solid #009fe3;
  top: 3px;
}

.nav-link:disabled > span {
    color: #b8b8b8;
  }


  .nav-tabs .nav-link:hover {
    border-bottom: none;  /* Change to your preferred border color */
    position: relative;
    border: 1px solid rgb(255, 255, 255);
    border-bottom: 4px solid #009fe3;
    top: 3px;
  }

.color-block {
    width: 170px;
    height: 170px;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
}

.BackIcon {
    width: 40px;
    height: 40px;
    border-radius: var(--SecondBorderRadius);
}

/* DropDowns */
.RegularDropDownContainer {
    transition: 0.2s;
    position: relative;
    border-radius: var(--RegularBorderRadius);
}
  
.RegularDropDownToggle {
    gap: var(--ButtonIconGap);
    user-select: none;
    padding: 0px 20px;
    height: var(--ButtonHeight);
    overflow-y: scroll;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--SecondBorderRadius);
    cursor: pointer;
    background-color: var(--White);
    transition: background-color .2s ease, border-radius .2s;
    text-wrap: none;
}
  
.RegularDropDownToggle.open {
    transition: .2s;
    border-radius: calc(var(--SecondBorderRadius) / 8) calc(var(--SecondBorderRadius) / 8) 0px 0px;
}
  
.RegularDropDownToggle:hover {
    background-color: var(--WhiteHover);
}
  
.RegularDropDownIndicator {
    transition: .2s ease;
    color: var(--Black);
}
  
.RegularDropDownIndicator.rotate {
    transform: rotate(180deg);
}



.RegularDropDownList {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--White);
    border-radius: 0px 0px 20px 20px;
    min-width: 100%;
    max-height: 0; /* Start with max-height 0 */
    opacity: 0; /* Start fully transparent */
    transform: translateY(-10px); /* Slide upwards initially */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
    overflow-y: hidden;
    transition: 
        max-height 0.3s ease, 
        opacity 0.3s ease, 
        transform 0.3s ease;
}

.RegularDropDownToggle.open + .RegularDropDownList {
    max-height: 200px; /* Expand to fit content */
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Slide into place */
    overflow-y: auto;
}
  
.RegularDropDownItem {
    padding: 10px;
    cursor: pointer;
    transition: .3s ease;
    white-space: normal; /* Allow text to wrap */
    word-break: break-word; /* Break long words if necessary */
}

.RegularDropDownItem.disabled {
    background-color: var(--LightGreyDisabled);
    text-decoration: line-through;
    pointer-events: none;
    cursor: not-allowed !important;
    color: var(--DarkGrey);
}

.RegularDropDownItem.selected {
    background-color: var(--Blue);
    color: var(--White);
}
  
.RegularDropDownItem:hover {
    background-color: var(--BlueHover);
    color: var(--White);
}

.BorderedDropDownContainer {
    position: relative;
    min-width: 200px;
    width: 100%;
    border-radius: var(--RegularBorderRadius);
}

.BorderedDropDownToggle {
    gap: var(--ButtonIconGap);
    user-select: none;
    padding: 0px 20px;
    height: var(--ButtonHeight);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--SecondBorderRadius);
    border: 2px solid var(--LightGrey);
    cursor: pointer;
    background-color: var(--White);
    transition: 0.3s ease-out;
    white-space: nowrap;
}

.input-container {
    position: relative;
    display: inline-block;
  }
  
.tooltip {
    visibility: hidden;
    background-color: red;
    color: white;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    position: absolute;
    bottom: 100%; /* Position above the input */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.3s;
}

.input-container:hover .tooltip,
.input-container .error:focus + .tooltip {
    visibility: visible;
    opacity: 1;
}


.BorderedDropDownToggle.error {
    border: 2px solid var(--Red);
}

.BorderedDropDownToggle.open {
    background-color: var(--White);
    border-radius: calc(var(--SecondBorderRadius) / 8) calc(var(--SecondBorderRadius) / 8) 0px 0px;
}

.BorderedDropDownIndicator {
    transition: transform 0.3s ease;
    color: var(--Black);
}

.BorderedDropDownIndicator.rotate {
    transform: rotate(180deg);
}

.BorderedDropDownList {
    border: 2px solid var(--LightGrey);
    border-top: 0px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--White);
    border-radius: 0px 0px 20px 20px;
    min-width: 100%;
    max-height: 0; /* Start with max-height 0 */
    opacity: 0; /* Start fully transparent */
    transform: translateY(-10px); /* Slide upwards initially */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
    overflow-y: hidden;
    transition: 
        max-height 0.3s ease, 
        opacity 0.3s ease, 
        transform 0.3s ease;
}

.BorderedDropDownToggle.open + .BorderedDropDownList {
    max-height: 200px; /* Expand to fit content */
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Slide into place */
    overflow-y: auto;
}

.BorderedDropDownToggle:hover {
    background-color: var(--WhiteHover);
}

.BorderedDropDownToggle:active, .BorderedDropDownToggle:focus {
    background-color: var(--White);
}

.BorderedDropDownToggle.open + .BorderedDropDownList {
    max-height: 200px;
    opacity: 1;
    transform: translateY(0);
}

.BorderedDropDownItem {
    border-bottom: 2px solid var(--LightGrey);
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    white-space: normal; /* Allow text to wrap */
    word-break: break-word; /* Break long words if necessary */
}

.BorderedDropDownItem:hover {
    background-color: var(--BlueHover);
    color: var(--White);
  }
  
.BorderedDropDownItem.disabled {
    background-color: var(--LightGreyDisabled);
    text-decoration: line-through;
    pointer-events: none;
    cursor: not-allowed !important;
    color: var(--DarkGrey);
}

.BorderedDropDownItem.selected {
    background-color: var(--Blue);
    color: var(--White);
}

/* Checkboxes */
.CheckBox {
    -webkit-appearance: none;
    background-color: var(--White);
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 1px solid var(--LightGrey);
    border-radius: 0.15em;
    display: grid;
    place-content: center;
    position: relative;
}

.CheckBox::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: transform 120ms ease-in-out;
    background-color: var(--Blue);
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.CheckBox:checked {
    background-color: var(--Blue);
    border-color: var(--Blue);
}

.CheckBox:checked::before {
    transform: scale(1);
    background-color: var(--White);
}


/* Titles */
.HUBTitle {
    font-weight: var(--FontWeightBold);
    font-size: var(--HubTitleFontSize);
}

.BlockTitle {
    font-weight: var(--FontWeightBold);
    font-size: var(--BlockTitleFontSize);
}

.TableTitle {
    font-weight: var(--FontWeightBold);
    font-size: var(--TableTitleFontSize);
}

.ButtonText,
.ButtonBlue,
.ButtonDarkBlue,
.ButtonLightGrey,
.ButtonWhite {
    line-height: 15px;
    font-weight: var(--FontWeightRegular);
    font-size: var(--ButtonTextFontSize);
}

.FlatTextLink {
    font-weight: var(--FontWeightRegular);
    font-size: var(--FlatTextLinkFontSize);
    color: var(--TextColorLink);
}

.FlatText {
    font-weight: var(--FontWeightRegular);
    font-size: var(--FlatTextFontSize);
}

.BeneathFlatText {
    font-weight: var(--FontWeightRegular);
    font-size: var(--BeneathFlatTextFontSize);
}

/* Border Radiusses */
.BorderRadius {
    border-radius: var(--RegularBorderRadius);
}

.BorderRadius2 {
    border-radius: var(--SecondBorderRadius);
}


/* Background colors */
.GradientBlue {
    background: var(--GradientBlue);
}

.LightBlue {
    background-color: var(--LightBlue);
}

.Blue {
    background-color: var(--Blue);
}

.DarkBlue {
    background-color: var(--DarkBlue);
}

.Black {
    background-color: var(--Black);
}

.Red {
    background-color: var(--Red);
}

.Orange {
    background-color: var(--Orange);
}

.Green {
    background-color: var(--Green);
}

.DarkGrey {
    background-color: var(--DarkGrey);
}

.LightGrey {
    background-color: var(--LightGrey);
}

.LightRed {
    background-color: var(--LightRed);
}

.LightOrange {
    background-color: var(--LightOrange);
}

.LightGreen {
    background-color: var(--LightGreen);
}

.White {
    background-color: var(--White);
}

/* Borders with varying thickness */

.GradientBlueBorder {
    border-image: var(--GradientBlue) 1;
    border-width: var(--RegularBorderThickness);
    border-style: solid;
}

.GradientBlueBorder2 {
    border-image: var(--GradientBlue) 1;
    border-width: var(--SecondBorderThickness);
    border-style: solid;
}

.LightBlueBorder {
    border: var(--RegularBorderThickness) solid var(--LightBlue);
}

.LightBlueBorder2 {
    border: var(--SecondBorderThickness) solid var(--LightBlue);
}

.BlueBorder {
    border: var(--RegularBorderThickness) solid var(--Blue);
}

.BlueBorder2 {
    border: var(--SecondBorderThickness) solid var(--Blue);
}

.DarkBlueBorder {
    border: var(--RegularBorderThickness) solid var(--DarkBlue);
}

.DarkBlueBorder2 {
    border: var(--SecondBorderThickness) solid var(--DarkBlue);
}

.BlackBorder {
    border: var(--RegularBorderThickness) solid var(--Black);
}

.BlackBorder2 {
    border: var(--SecondBorderThickness) solid var(--Black);
}

.RedBorder {
    border: var(--RegularBorderThickness) solid var(--Red);
}

.RedBorder2 {
    border: var(--SecondBorderThickness) solid var(--Red);
}

.OrangeBorder {
    border: var(--RegularBorderThickness) solid var(--Orange);
}

.OrangeBorder2 {
    border: var(--SecondBorderThickness) solid var(--Orange);
}

.GreenBorder {
    border: var(--RegularBorderThickness) solid var(--Green);
}

.GreenBorder2 {
    border: var(--SecondBorderThickness) solid var(--Green);
}

.DarkGreyBorder {
    border: var(--RegularBorderThickness) solid var(--DarkGrey);
}

.DarkGreyBorder2 {
    border: var(--SecondBorderThickness) solid var(--DarkGrey);
}

.LightGreyBorder {
    border: var(--RegularBorderThickness) solid var(--LightGrey);
}

.LightGreyBorder2 {
    border: var(--SecondBorderThickness) solid var(--LightGrey);
}

.LightRedBorder {
    border: var(--RegularBorderThickness) solid var(--LightRed);
}

.LightRedBorder2 {
    border: var(--SecondBorderThickness) solid var(--LightRed);
}

.LightOrangeBorder {
    border: var(--RegularBorderThickness) solid var(--LightOrange);
}

.LightOrangeBorder2 {
    border: var(--SecondBorderThickness) solid var(--LightOrange);
}

.LightGreenBorder {
    border: var(--RegularBorderThickness) solid var(--LightGreen);
}

.LightGreenBorder2 {
    border: var(--SecondBorderThickness) solid var(--LightGreen);
}

.WhiteBorder {
    border: var(--RegularBorderThickness) solid var(--White);
}

.WhiteBorder2 {
    border: var(--SecondBorderThickness) solid var(--White);
}

/* TextColors */
.GradientBlueText {
    background: var(--GradientBlue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.LightBlueText {
    color: var(--LightBlue);
}

.BlueText {
    color: var(--Blue);
}

.DarkBlueText {
    color: var(--DarkBlue);
}

.BlackText {
    color: var(--Black);
}

.RedText {
    color: var(--Red);
}

.OrangeText {
    color: var(--Orange);
}

.GreenText {
    color: var(--Green);
}

.DarkGreyText {
    color: var(--DarkGrey);
}

.LightGreyText {
    color: var(--LightGrey);
}

.LightRedText {
    color: var(--LightRed);
}

.LightOrangeText {
    color: var(--LightOrange);
}

.LightGreenText {
    color: var(--LightGreen);
}

.WhiteText {
    color: var(--White);
}

/* TextColors */
.GradientBlueTextBold {
    background: var(--GradientBlue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: var(--FontWeightBold);
}

.LightBlueTextBold {
    color: var(--LightBlue);
    font-weight: var(--FontWeightBold);
}

.BlueTextBold {
    color: var(--Blue);
    font-weight: var(--FontWeightBold);
}

.DarkBlueTextBold {
    color: var(--DarkBlue);
    font-weight: var(--FontWeightBold);
}

.BlackTextBold {
    color: var(--Black);
    font-weight: var(--FontWeightBold);
}

.RedTextBold {
    color: var(--Red);
    font-weight: var(--FontWeightBold);
}

.OrangeTextBold {
    color: var(--Orange);
    font-weight: var(--FontWeightBold);
}

.GreenTextBold {
    color: var(--Green);
    font-weight: var(--FontWeightBold);
}

.DarkGreyTextBold {
    color: var(--DarkGrey);
    font-weight: var(--FontWeightBold);
}

.LightGreyTextBold {
    color: var(--LightGrey);
    font-weight: var(--FontWeightBold);
}

.LightRedTextBold {
    color: var(--LightRed);
    font-weight: var(--FontWeightBold);
}

.LightOrangeTextBold {
    color: var(--LightOrange);
    font-weight: var(--FontWeightBold);
}

.LightGreenTextBold {
    color: var(--LightGreen);
    font-weight: var(--FontWeightBold);
}

.WhiteTextBold {
    color: var(--White);
    font-weight: var(--FontWeightBold);
}

.ButtonBlue,
.ButtonDarkBlue,
.ButtonLightGrey,
.ButtonWhite,
.ButtonBack {
    user-select: none;
    display: flex;
    /* min-width: 200px; */
    height: var(--ButtonHeight);
    padding: 0px 25px;
    justify-content: center;
    align-items: center;
    border: 0px;
    border-radius: var(--SecondBorderRadius);
    background-color: transparent;
    white-space: nowrap;
}

.ButtonBlue:disabled,
.ButtonDarkBlue:disabled,
.ButtonLightGrey:disabled,
.ButtonWhite:disabled,
.ButtonBack:disabled {
    background-color: var(--DarkGreyDisabled);
    cursor: not-allowed;
    pointer-events: none;
    color: var(--White);
}

.ButtonBlue {
    transition: .2s;
    background-color: var(--Blue);
    gap: var(--ButtonTextIconGap);
}

.ButtonBlue:hover {
    transition: .2s;
    background-color: var(--BlueHover);
    cursor: pointer;
}

.ButtonDarkBlue {
    transition: .2s;
    background-color: var(--DarkBlue);
    gap: var(--ButtonTextIconGap);
}

.ButtonDarkBlue:hover {
    transition: .2s;
    background-color: var(--DarkBlueHover);
    cursor: pointer;
}

.ButtonLightGrey {
    transition: .2s;
    background-color: var(--LightGrey);
    gap: var(--ButtonTextIconGap);
}

.ButtonLightGrey:hover {
    transition: .2s;
    background-color: var(--LightGreyHover);
    cursor: pointer;
}

.ButtonWhite {
    transition: .2s;
    background-color: var(--White);
    gap: var(--ButtonTextIconGap);
}

.ButtonWhite:hover {
    transition: .2s;
    background-color: var(--WhiteHover);
    cursor: pointer;
}

.ButtonBack {
    gap: var(--ButtonTextIconGap);
}

/* SearchBars */
.ButtonComponentImageStyling {
    max-width: 25px;
    max-height: 20px;
    object-fit: contain;
}

.RegularSearchBarContainer, .BorderedSearchBarContainer {
    position: relative;
}

.RegularSearchBar, .BorderedSearchBar {
    background-color: var(--White);
    border: 0px;
    height: 45px;
    min-width: 200px;
    padding-left: 10px;
    padding-left: 20px;
    border-radius: var(--SecondBorderRadius);
    transition: .3s;
}

.RegularSearchBar:focus, .BorderedSearchBar:focus {
    outline: none;
}

.RegularSearchBar:hover, .BorderedSearchBar:hover {
    transition: .3s;
    background-color: var(--WhiteHover);
}

.RegularSearchBar:active, .RegularSearchBar:focus, .BorderedSearchBar:active, .BorderedSearchBar:focus {
    transition: .3s;
    background-color: var(--White);
}

.BorderedSearchBar {
    border: 2px solid var(--LightGrey);
}

.BorderedSearchBar.error {
    border: 2px solid var(--Red);
}

.RegularSearchBar, .BorderedSearchBar {
    width: 100%;
    box-sizing: border-box;
}

.RegularSearchBarResponseList {
    display: none;
    position: absolute;
    width: 100%;
    top: 100%; /* Position the list right below the input */
    left: 0;
    background-color: var(--White);
    z-index: 10; /* Ensure it appears above other elements */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add some shadow for better visibility */
    overflow-y: auto; /* Enable scroll if content exceeds available space */
    max-height: 300px; /* Optional: Limit the maximum height */
    border-radius: var(--RegularBorderRadius);
}

.RegularSearchBarResponseList.open {
    display: table;
}

.BorderedSearchBarResponseList {
    display: none;
    background-color: var(--White);
    opacity: 0;
    max-height: 0;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 10;
    border: 1px solid var(--LightGrey);
    transform: translateY(-10px);
    overflow-y: scroll;
    border-radius: var(--RegularBorderRadius);
    transition: max-height 0.3s ease, opacity 0.3s ease, transform 0.3s ease, padding 0.3s ease;
  }
  
  .BorderedSearchBarResponseList.open {
    display: table;
    opacity: 1;
    max-height: 300px; /* Adjust this value as needed */
    transform: translateY(0);
  }
  
  .BorderedSearchBarResponseList.closed {
    opacity: 0;
    max-height: 0;
    transform: translateY(-10px);
  }

.SearchBarIcon {
    user-select: none;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
}

.InputRow {
    display: flex;
    gap: 1rem;
}

/* Widths for components */

.ExtraSmallWidth {
    flex: 0.125;
}

.SmallWidth {
    flex: 0.25;
}

.MediumWidth {
    flex: 0.5;
}

.FullWidth {
    flex: 1;
}

/* Text Input styling */

.RegularTextInput,
.BorderedTextInput,
.BorderedTextInputWithButton,
.RegularMoneyInput,
.BorderedMoneyInput,
.RegularSquareMoneyInput,
.BorderedSquareMoneyInput {
    height: 45px;
    background-color: var(--White);
    border: 0px;
    padding: 10px;
    padding-left: 20px;
    /* min-width: 200px; */
    border-radius: var(--SecondBorderRadius);
    width: 100%;
    outline: none;
}

.RegularTextInput:disabled,
.BorderedTextInput:disabled,
.BorderedTextInputWithButton:disabled,
.RegularMoneyInput:disabled,
.BorderedMoneyInput:disabled,
.RegularSquareMoneyInput:disabled,
.BorderedSquareMoneyInput:disabled {
    height: 45px;
    background-color: var(--White);
    border: 0px;
    padding: 10px;
    padding-left: 20px;
    background-color: var(--LightGrey);
    /* min-width: 200px; */
    border-radius: var(--SecondBorderRadius);
    width: 100%;
    outline: none;
}

.RegularSquareMoneyInput,
.BorderedSquareMoneyInput {
    border-radius: var(--RegularBorderRadius);
}




.MoneyInputWrapper {
    position: relative;
}

.MoneyInputWrapper.empty::before {
    color: var(--DarkGrey); /* Light grey when empty */
}

.MoneyInputWrapper.filled::before {
    color: var(--Black); /* Darker color when filled */
}

.MoneyInputWrapper::before {
    content: "\20AC";
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.RegularMoneyInput,
.BorderedMoneyInput,
.RegularSquareMoneyInput,
.BorderedSquareMoneyInput {
    padding-left: 30px;
}

/* Number Input styling */
.RegularNumberInput,
.BorderedNumberInput,
.RegularSquareNumberInput,
.BorderedSquareNumberInput {
    height: 45px;
    background-color: var(--White);
    border: 0px;
    padding: 10px;
    padding-left: 20px;
    border-radius: var(--SecondBorderRadius);
    width: 100%;
    outline: none;
}


.BorderedTextInput, .BorderedMoneyInput, .BorderedSquareMoneyInput, .BorderedNumberInput, .BorderedSquareNumberInput {
    border: 2px solid var(--LightGrey);
}

.BorderedTextInput.error, .BorderedMoneyInput.error, .BorderedSquareMoneyInput.error, .BorderedNumberInput.error, .BorderedSquareNumberInput.error {
    border: 2px solid var(--Red);
}

.RegularSquareNumberInput,
.BorderedSquareNumberInput {
    border-radius: var(--RegularBorderRadius);
}

/* Code Accordion Styling */

.accordion, .accordion:hover {
    background-color: transparent;
    padding: 0px;
}

.accordion-button {
    width: 100%;
}


/* CollapsiblePanel.css */

.collapsible-panel {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 10px 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.collapsible-header {
    background-color: #f7f7f7;
    padding: 15px 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapsible-header h3 {
    margin: 0;
    font-size: 18px;
}

.collapsible-header .arrow {
    transition: transform 0.3s ease;
}

.collapsible-header .arrow.open {
    transform: rotate(180deg);
}

.collapsible-content {
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 0;
}

.content-inner {
    padding: 15px 20px;
    background-color: #fff;
    border-top: 1px solid #ddd;
}


/* Input with button right en text left */

.TextInputWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .InputContainer {
    display: flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .Prefix {
    height: var(--ButtonHeight);
    padding: 0 20px;
    font-size: 15px;
    border-top-left-radius: var(--SecondBorderRadius);
    border-bottom-left-radius: var(--SecondBorderRadius);
    color: var(--TextColorBlack);
    display: flex;
    align-items: center;
    justify-content: center;
    border: var(--SecondBorderThickness) solid var(--LightGrey);
    border-right: 0px solid var(--LightGrey);
  }

  .BorderedTextInputWithButton {
    border-radius: 0px;
    border: 2px solid var(--LightGrey);
    border-right: 0px;
  }

  .buttonBorderedRight {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 2px solid var(--LightGrey);
    border-right: 0px;
  }

  .buttonBorderedLeft {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border: 2px solid var(--LightGrey);
  }
  
  
  .CopyButton {
    height: var(--ButtonHeight);
    padding: 8px 20px;
    font-size: 14px;
    color: var(--Blue);
    border: 2px solid var(--Blue);
    background: none;
    cursor: pointer;
    transition: color 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top-right-radius: var(--SecondBorderRadius);
    border-bottom-right-radius: var(--SecondBorderRadius);
  }
  
  .CopyButton:hover {
    background-color: var(--Blue);
    color: var(--White);
  }

/* Input with button right en text left */
