.parentBoxEditUser {
  height: 71vh !important;
}

.inputFieldEditUser {
  border: 2px solid #e6e6e6e6;
  border-radius: 20px;
  height: 35px;
}

.inputFieldEditUser.error {
  border: 2px solid #ff0000e6 !important;
}

.inputFieldEditUser::placeholder {
  font-weight: 600;
  color: #bebebee6;
}

.inputFieldEditUser:focus, .inputFieldEditUser:active {
  border: 2px solid #c2c1c1e6 !important;
  outline: none !important;
  padding-left: 10px;
}

.btnEditUser {
  background-color: #0586D3;
  color: white;
  border-color: #0586D3;
  width: fit-content;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  height: 35px;
  line-height: 10px;
}

.btnEditUser:focus, .btnEditUser:active, .btnEditUser:hover {
  background-color: #036daa !important;
  color: white !important;
  border-color: #036daa !important;
}

.titlesEditUsers {
  display: flex;
  align-self: center !important;
}

.iconInfoStyle {
  margin-top: 3px;
  margin-left: 3px;
}

.iconInfoStyle:hover {
  cursor: pointer;
}

.btnPasswordShow {
  background-color: #0586D3;
  color: white;
  border-color: #0586D3;
  border-radius: 0px;
  border-right: 1px solid rgb(255, 255, 255);
  height: 35px;
  width: fit-content;
  line-height: 10px;
}

.btnPasswordShow:focus, .btnPasswordShow:active, .btnPasswordShow:hover {
  border-right: 1px solid rgb(255, 255, 255) !important;
  background-color: #036daa !important;
  color: white !important;
  border-color: #036daa !important;
}

.roles-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.role-card {
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.role-title {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.role-description {
  overflow: scroll;
  max-height: 90px;
  font-size: 0.95rem;
  color: #555;
}

.text-primary {
  color: #007bff; /* Adjust to match your primary color */
}

.editUserProfilePicture {
  max-width: 200px;
  max-height: 200px;
  border-radius: 100%;
}