/* [[[ Main Page Styling ]]] */
.payment-mainpage-maincontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 65px;
}

.payment-mainpage-title {
    font-size: 55px;
    font-weight: 900;
    width: 400px;
    margin-bottom: 2rem;
}

@media only screen and (max-width: 1300px) {
    .payment-mainpage-terminal-img {
        width: 100%;
        height: auto;
    }

    .payment-mainpage-textcontainer-right {
        width: 50%;
        margin-left: 50px !important;
    }
}

@media only screen and (max-width: 900px) {
    .payment-mainpage-textcontainer-left {
        display: none;
    }

    .payment-mainpage-textcontainer-right {
        margin-left: 0px !important;
    }

    .payment-mainpage-title {
        width: 100%;
    }
}

.payment-mainpage-textcontainer-left {
    flex-grow: 1;
}

.payment-mainpage-textcontainer-right {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-left: 140px;
    flex-grow: 1;
    max-width: 600px;
}

.payment-mainpage-terminal-img {
    /* Undraggable image */
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

/* [[[ Account creation page ]]] */

.payment-account-creation-main-container {
    width: 75%;
}

.payment-account-creation-form {
    display: flex;
    flex-direction: column;
    gap: 11px;
    padding: 2.3rem;
}

.payment-account-creation-form > label {
    font-weight: 100;
}

@media only screen and (max-width: 1300px) {
    .payment-account-creation-main-container {
        width: 100%;
    }
}

/* [[[ Account overview page ]]] */

.account-overview-header {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 8px;
}

.onboarding-payment-information-header {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1.75rem;
}

.account-overview-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 2.5rem;
}

.account-overiew-info-tile {
    padding: 2.1rem;
    width: 100%;
}

.account-overiew-info-tile > div.account-overview-bordered-div {
    border-top: 2px solid #EBEBEB;
    border-bottom: 2px solid #EBEBEB;
    padding-top: 10px;
    padding-bottom: 40px;
}

.account-overiew-info-tile > table {
    width: 100%;
    border-collapse: collapse;
}

.account-overiew-info-tile > table > tbody > tr {
    border-top: 2px solid #EBEBEB;
    top: 0px;
}

.account-overiew-info-tile > table > tbody > tr > td {
    width: 50%;
    padding: 0.9rem 0;
    font-size: 16px;
    vertical-align: top;
}

/* Merchant info */
.account-overview-merchant-info > table {
    margin-bottom: 4rem;
}

/* Onboarding */

.account-overview-merchant-onboarding > table > tbody > tr > td {
    padding-bottom: 2rem !important;
}

.account-overview-merchant-onboarding > table > tbody > tr:last-child  {
    border-bottom: 2px solid #EBEBEB;
}

.account-overview-merchant-onboarding > table > tbody > tr:last-child > td  {
    padding-bottom: 3rem !important;
}

.account-overview-merchant-paymentmethods > table > tbody > tr {
    position: relative;
    border-top: 0px;
}

/* Lines for the merchant payment method table because it's half the size */
.account-overview-merchant-paymentmethods > table > tbody > tr::before {
    content: "";
    position: absolute;
    top: -3px;
    left: 0px;
    width: 200%;
    height: 2px;
    background-color: #EBEBEB;
}

.account-overview-merchant-paymentmethods > table > tbody > tr:last-child:after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0px;
    width: 200%;
    height: 2px;
    background-color: #EBEBEB;
}