   .email-header {
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin-bottom: 1rem;
   }

   .email-header h2 {
     font-size: 1.25rem;
     font-weight: 600;
   }

   .new-email-btn {
     padding: 0.5rem 1rem;
     background-color: #3b82f6;
     color: white;
     border-radius: 0.25rem;
     font-size: 0.875rem;
   }

   .search-bar {
     display: flex;
   }

   .SearchIcon {
     color: #B0B0B0;
   }

   .search-bar input {
     flex-grow: 1;
     padding: 0.5rem;
     border: 1px solid #B0B0B0;
   }

   .search-input {
     color: black;
   }

   .pagination-btn {
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    display: flex;
   }

   .pagination-btn:not(:first-child):not(:last-child) {
     color: #38B0E3;
   }

   .email-item {
     display: flex;
     align-items: center;
     padding: 0.5rem 1rem;
     border-bottom: 1px solid #e2e8f0;
   }

   .checkbox-container {
     flex-shrink: 0;
     width: 24px;
     display: flex;
     justify-content: center;
   }

  .email-container {
    border: 1px solid #B0B0B0;
    border-radius: 0.5rem;
    overflow-x: auto; /* Prevents breaking out of container */
  }
  
  .email-list {
    display: flex;
    flex-direction: column;
  }
  
  .email-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .checkbox-container {
    flex-shrink: 0;
    width: 24px;
    display: flex;
    justify-content: center;
  }

  .email-list {
    display: flex;
    flex-direction: column;
  }
  
  .email-item {
    display: flex;
    align-items: flex-start;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #e2e8f0;
    gap: 0.5rem;
  }
  
  .email-subject {
    flex-grow: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    font-weight: bold;
  }
  
  .email-date-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.875rem;
    color: #6b7280;
    margin-top: 0.25rem;
  }
  
  .email-date-container .email-date {
    white-space: nowrap;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
  }
  
  

   .pagination-btn:first-child,
   .pagination-btn:last-child {
     background-color: #009FE3;
     color: white;
   }

   .search-btn {
     padding: 0.5rem;
     background-color: #f3f4f6;
     border: 1px solid #e5e7eb;
     border-left: none;
     border-radius: 0 0.25rem 0.25rem 0;
   }

   .email-list-header {
     display: flex;
     justify-content: space-between;
     align-items: center;
     border-bottom: 1px solid #B0B0B0;
   }

   .email-list-header > span {
    text-align: left;
   }

   .pagination button:focus {
     outline: none;
   }

   .items-per-page {
     border: 1px solid #e2e8f0;
     border-radius: 0.375rem;
   }

   .email-item {
     display: flex;
     padding: 0.5rem 0;
     margin: 0px !important;
   }

   .email-subject {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
   }

   .email-date {
     flex-shrink: 0;
     width: 100px;
     text-align: right;
   }

   .no-results {
     text-align: center;
     padding: 1rem 0;
     color: #6b7280;
   }

   .pagination {
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin-top: 1rem;
   }

   .pagination select,
   .pagination button {
     padding: 0.25rem 0.5rem;
     border: 1px solid #e5e7eb;
     border-radius: 0.25rem;
   }

   .pagination button:disabled {
     opacity: 0.5;
     cursor: not-allowed;
   }

   @media (max-width: 640px) {
     .email-header {
       flex-direction: column;
       align-items: flex-start;
     }

     .new-email-btn {
       margin-top: 0.5rem;
       align-self: flex-end;
     }

     .email-list-header,
     .email-item {
       font-size: 0.875rem;
     }

     .pagination {
       flex-wrap: wrap;
       gap: 0.5rem;
     }
   }
   
   .email-page-sizer {
    border-radius: 100px !important;
    padding-left: 1.5rem!important;
    width: 100px;
    height: 40px;
    border: 1px solid #A1A1A1 !important;
   }