/* PageTemplate styling */
.mainBox {
  position: fixed;
  overflow-y: auto;
  min-height: fit-content;
  top: 0px;
  width: calc(100% - 270px);
  height: calc(100%);
  left: 270px;
  margin-left: 0px !important;
  /* z-index: 1; */
  background-color: #e2eefb;
  padding-top: 136px;
  margin-top: 0px !important;
}

.widthContentMainbox {
  min-width: 95%;
  width: 95%;
  margin-left: 10px;
  margin-right: 10px;

}

.mainBoxSmallSidebar {
  width: calc(100% - 100px);
  left: 100px;
}

/* PageTemplate styling */

.file-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
  
.file-upload-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.file-upload-input {
  display: none;
}

.file-upload-text {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.file-upload-label:hover {
  border-color: #aaa;
}

.file-upload-label.dragover {
  border-color: #aaa;
}

.file-upload-label.dragover .file-upload-text {
  font-weight: bold;
}

.file-upload-preview-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
}

.file-upload-preview {
  position: relative;
  width: 150px;
  height: 150px;
  margin-right: 10px;
}

.file-upload-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid black;
  z-index: 60;
}

.file-upload-preview button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  font-size: 12px;
  background-color: #f00;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.file-upload-index-remove {
  cursor: pointer;
  background: #ffffff;
  border-radius: 10px;
  width: 20px;
  display: flex;
  justify-content: center;
  border:1px solid black;
}

.file-upload-index {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff;
  border:1px solid black;
  text-align: top;
  width: 20px;
  height: 20px;
  font-size: 12.5px;
  font-weight: 500;
  border-radius: 10px;
  color: rgb(0, 0, 0);

}

.container {
  width: 400px;
  height: 400px;
  border: 1px solid black;
  position: relative;
}

.draggable-box {
  width: 100px;
  height: 100px;
  background-color: blue;
  color: white;
  text-align: center;
  line-height: 100px;
  position: absolute;
}

@media only screen and (max-width: 1000px) {
  .widthContentMainbox {
    width: calc(100% - 210px);
  }
}

@media only screen and (max-width: 1112px) {
  
  .widthContentMainbox {
    display: flex;
    justify-content: center;
    width: 100% ;
  }
} 

@media only screen and (max-width: 370px) {
    .widthContentMainbox {
      display: flex;
      justify-content: center;
    }
}