.headBox {
    min-height: 100vh;
    border-top-left-radius: 50px;
    position: fixed;
    overflow-y: auto;
    top: 69px;
    z-index: 1;
    background-color: #e9e9e9;
  }


.boxes {
    background-color: white;
    border-radius: 15px;
    height: 300px;
    width: 300px;
    display: flex;
    margin-bottom: 50px;
    flex-direction: column;
    /* position: relative;
    left: 12.5%; */
}

.hubBoxes {
    width: 80px;
    height: 80px;
    position: relative;
    bottom: 37px;
    left: 40px;
    display: flex;
    justify-content: center;
}

.imgStyleBoxes {
    box-sizing: content-box;
}

.boxes:nth-child(4) h4 {
    font-size: 1.19rem !important;
}

.widthParentApps {
    width: 260px !important;
}

.boxText {
    position: relative;
    bottom: 15px;
}


.parentApps {
    overflow-y: scroll;
    height: 125px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3px; /* Ruimte tussen de items */
}

.iconActive {
    width: 40px;
    height: 40px;
    background-color: #b5b5b5;
    border-radius: 5px;
    margin-bottom: 10px;
    object-fit: contain;
}

.iconActivePNG {
    width: 48px;
    height: 48px;
    background-color: #b5b5b5;
    border-radius: 5px;
}

.icons {
    width: 35px;
    height: 35px;
    padding-top: 0px;
    padding-left: 0px;
}

.iconsSVG {
    width: 100%;
    height: 100%;
}

.iconsSVG2 {
    /* margin-top: 0.40rem; */
    width: 40px;
    height: 40px;
}

.DashBoxIconCenteren {
    display: flex;
    justify-content: center;
    align-items: center;
}

.DashBoxIconCenteren > img {
    filter: brightness(0) invert(1);
}

.iconActive {
    filter: none !important;
}

.boxInvisible{
    height: 300px;
    width: 300px;
}

.subTitle {
    font-size: x-small;
    width: 200px;
    color: gray;
}

.appstoreBox {
    background-color: #e6e6e6;
    color: #919191;
    height: 40px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 100px;
}

.appstoreText {
    background-color: #e6e6e6;
    color: #919191;
    height: 40px;
    width: 50px;
    font-size: small;
    font-weight: 500;
}

.responsiveDashboard {
    margin-bottom: 20px;
    margin-top: 37px;
}

.widthRowBoxes {
    width: 98%;
}

.dashboard-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Ensure 4 items per row on wider screens */
    margin-top: 40px;
}

.dashboard-container > div {
    justify-self: center;
}

.hubsWeb {
  position: relative;
  height: calc(100vh - 160px);
  margin-bottom: 0px;
  width: calc(100vw - 160px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.logoCenterCircle {
  width: 150px;
  height: auto;
}

.backgroundCenterCircle {
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 159, 227, 1) 0%, rgba(2, 90, 122, 1) 100%);
  padding: 30px;
  border-radius: 50%;
  height: 225px;
  width: 225px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.hub-node {
  position: relative; /* Required to position the status icon correctly */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  padding: 0px 10px;
  user-select: none;
  /* Verwijder de floatingEffect animatie */
  /* animation: floatingEffect 3s ease-in-out infinite; */
}

.status-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  z-index: 5;
}

.hub-node p {
  font-size: 12px;
  margin: 0;
}

.hub-node h3 {
  margin-top: 5px;
  font-size: 15px;
}

.hub-node:hover {
  background-color: rgb(209, 209, 209);
}

.hub-connections {
  position: absolute;
  top: 0;
  left: -2.5%;
  width: 105%;
  height: 100%;
  z-index: 1;
}

.hub-connections line {
  stroke-linecap: round;
  stroke-linejoin: round;
  /* Animatie toevoegen via SVG animate */
}

.hub-node.active-status {
  box-shadow: 0 0 10px 0px rgba(0, 156, 222, 0.7); /* Blue glow */
}

@keyframes linePulse {
  0% {
    stroke-width: 4px;
    stroke: #009cde; /* Starting color */
  }
  50% {
    stroke-width: 6px;
    stroke: #009cde; /* Lighter blue */
  }
  100% {
    stroke-width: 4px;
    stroke: #009cde; /* Back to original color */
  }
}

.active-line {
  animation: linePulse 4s ease-in-out infinite; /* Verhoogde duur van 2s naar 4s */
}

/* Float animaties */
@keyframes floatUpDown {
  0% {
    transform: translateY(0px) translateX(0px);
  }
  50% {
    transform: translateY(-5px) translateX(5px);
  }
  100% {
    transform: translateY(0px) translateX(0px);
  }
}

@keyframes floatDownUp {
  0% {
    transform: translateY(0px) translateX(0px);
  }
  50% {
    transform: translateY(5px) translateX(-5px);
  }
  100% {
    transform: translateY(0px) translateX(0px);
  }
}

@keyframes floatLeftRight {
  0% {
    transform: translateY(0px) translateX(0px);
  }
  50% {
    transform: translateY(5px) translateX(-10px);
  }
  100% {
    transform: translateY(0px) translateX(0px);
  }
}

@keyframes floatRightLeft {
  0% {
    transform: translateY(0px) translateX(0px);
  }
  50% {
    transform: translateY(-5px) translateX(10px);
  }
  100% {
    transform: translateY(0px) translateX(0px);
  }
}

/* Klassen om animaties toe te passen op nodes */
.floatUpDown {
  animation: floatUpDown 6s ease-in-out infinite; /* Verhoogde duur van 3s naar 6s */
}

.floatDownUp {
  animation: floatDownUp 8s ease-in-out infinite; /* Verhoogde duur van 4s naar 8s */
}

.floatLeftRight {
  animation: floatLeftRight 7s ease-in-out infinite; /* Verhoogde duur van 3.5s naar 7s */
}

.floatRightLeft {
  animation: floatRightLeft 9s ease-in-out infinite; /* Verhoogde duur van 4.5s naar 9s */
}

  

@media only screen and (max-width: 1600px) {
    body:not(.collapsed) .dashboard-container {
        grid-template-columns: repeat(3, 1fr); 
    }
}

@media only screen and (max-width: 1530px) {
    .dashboard-container {
        grid-template-columns: repeat(3, 1fr); 
    }
}

@media only screen and (max-width: 1200px) {
    .dashboard-container {
        grid-template-columns: repeat(2, 1fr); 
    }

    body:not(.collapsed) .dashboard-container {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media only screen and (max-width: 650px) {
    .dashboard-container {
        grid-template-columns: repeat(1, 1fr); 
    }

    body:not(.collapsed) .dashboard-container {
        grid-template-columns: repeat(1, 1fr); 
    }
}

@media only screen and (max-height: 650px) {
  .backgroundCenterCircle {
    width: 125px;
    height: 125px;
  }

  .logoCenterCircle {
    width: 85px;
  }

  .titlehubs {
    font-size: 9px !important;
  }
}