
/* Hide arrow for increment/decrement */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.ordersProcessBox {
    width: 70%;
}

.ordersCustomerBox {
    width: 28%;
}

.orderTable .newButtons:first-child {
    background-color: #009fe3 !important;
    color: white !important;
}

.orderTable .newButtons:first-child:hover {
    background-color: rgb(0, 106, 145) !important;
    color: white !important;
  }

/* orders edit */

.ordersEditContainer {
    display: flex;
    justify-content: space-between;
}

/* transition label to top input fields, needs to be fine tuned */




.clientSearch {
    padding: 2.5px;
    padding-left: 5px;
    padding-right: 28px;
    width: 100%;
    height: 60px;
    font-size: 16px;
    color: #000;
    padding: 0 15px;
    background: transparent;
    border: 1.2px solid #ced4da;
    outline: none;
    border-radius: 5px;
}

.clientSearch:focus, .clientSearch:not(:placeholder-shown) {
    border-radius: 5px 5px 0 0;
}
  

.clientSearch-label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #6c757d;
    padding: 0 5px;
    pointer-events: none;
    transition: 0.4s;
    background-color: transparent;
}

.clientSearch:focus ~ .clientSearch-label,
.clientSearch:not(:placeholder-shown) ~ .clientSearch-label {
    top: 0;
    font-size: 14px;
    transform: translateY(-50%);
    --bs-bg-opacity: 1;
     background: linear-gradient(
        to bottom,
        #FFFFFF 0%,
        #FFFFFF 50%,
        #F8F8F8 50%,
        #F8F8F8 100%
    ) !important;
}

.borderedSearchStyle {
    position: relative;
    width: 100%;
}

.clientSearch:focus ~ .clientSearch-label,
.clientSearch:not(:placeholder-shown) ~ .clientSearch-label {
  top: 0;
  font-size: 14px;
  background: #fff;
}

.productsSearch .clientSearch {
    padding-right: 28px;
}

.productsSearch .clientSearch-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.productsSearch .borderedSearchStyle {
    background-color: #F8F8F8;
}

.verwerk-list-group-item:nth-child(0) {
    border-top: 1px solid #B6B6B6;
}

.lightGrayStandardButton2 {
    font-family: 'Nunito';
   background-color: #E3E3E3;
   color: #1C1C1C;
   font-weight: 0;
   padding: 5px 25px;
   border-radius: 20px;
   outline: 0px solid #f8f8f8 !important;
   border: 0px solid #f8f8f8 !important;
   height: 44px;
}

.new-link-color {
    color: #3FB7EA;
    text-decoration: none;
}

/* 
st-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);*/

.bottom-border-gray {
    border-bottom: 1px solid #B6B6B6;
}

.border-right-gray {
    border-right: 1px solid #B6B6B6;
}

.betaling-overzicht-container {
    border: 1px solid #B0B0B0;
    border-radius: 0.5rem;
    overflow-x: auto;
    width: 48.5%;
}

.betaling-overzicht-container-headers {
    background-color: #EEEEEE;
}

.text-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* transition label to top input fields, needs to be fine tuned, transition */

.customerTableRow:hover, .tableRow:hover {
    background-color: rgba(0,159,227,1);
    color: whitesmoke;
    cursor: pointer;
}

.clientSearch:focus {
    outline: none !important;
}

.clientSearching {
    border-radius: 10px;
    padding: 2.5px;
    padding-left: 5px;
    padding-right: 28px;
    border-bottom: 0px !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.clientSearching:focus {
    outline: none !important;
}


.clientSearchIcon {
    position: absolute;
    right: 10px; /* Adjust based on preference */
    top: 50%;
    transform: translateY(-50%);
}

.form-select-shipping {
    background-color: #EEEEEE;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 4px 24px 4px 8px;
    font-size: 14px;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%23212529' d='M4.646 6.646a.5.5 0 0 1 .708 0L8 9.293l2.646-2.647a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    cursor: pointer;
  }
  
  .form-select-shipping:focus {
    outline: none;
    border-color: #ced4da;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
  }

.shipping-cost {
    border: 1px solid #B5B5B5;
    outline: none;
    width: 75px;
    border-radius: 5px;
}

.inputSaleOrder, .inputSaleOrder:focus, .inputSaleOrder:active {
    border: 1px solid #e5e5e5;
    outline: none;
    width: 60px;
    border-radius: 5px;
}

.euroSaleButton {
    width: 40px;
    background-color: white;
    border-radius: 5px 0px 0px 5px;
    color: #e5e5e5;
    border: 1px solid #e5e5e5;
}

.activeSaleType {
    background-color: #cfedfa !important;
    border: 1px solid #009fe3 !important;
    color: #009fe3 !important;
}

.percentageSaleButton {
    background-color: white;
    color: #e5e5e5;
    width: 40px;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #e5e5e5;
}

.amountCountButtons {
    background-color: white;
    border: 1px solid #ffffff;
    width: 38px;
    height: 38px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-self: center;
}

.amountParent {
    border: 2px solid #f8f8f8;
    padding: 0px 0px;
    display: flex;
    border-radius: 7px;
    flex-direction: row;
    justify-content: space-around;
}

.tableRow td {
    height: 44px !important;
}

.amountCount {
    border: 2px solid #f8f8f8;
    border-bottom: 0px ;
    border-top: 0px ;
}

.tableProductSearch td, .tableProductSearch th {
    border: 0px !important;
}

.tableProductSearch tr {
    border-bottom: 1px solid #c9c9c9 !important;
}

.noteSubtotal {
    height: 300px;
}


.btwSelect {
    border-radius: 10px;
    padding: 2.5px;
    padding-left: 5px;
    width: 125px;
    padding-right: 28px;
    border: 2px solid rgb(243, 243, 243);
    color: black;
    background-color: white;
}

.btwSelect:focus {
    outline: none !important;
}

.textareaStyleOrders {
    border: 2px solid rgb(243, 243, 243);
    border-radius: 10px;
    padding: 8px;
    height: 100%;
}

.textareaStyleOrders:focus {
    outline: none !important;
}

.extra-info {
    height: 195px;
}

.saleInputStyle {
    border: 2px solid rgb(243, 243, 243);
    border-bottom-left-radius: 10px;
    border-Top-left-radius: 10px;
}

.saleTypeStyle {
    background-color: white;
    color: black;
    border: 2px solid rgb(243, 243, 243);
}

.dropdownZending {
    width: 30%;
    border-radius: 10px;
    padding: 2.5px;
    padding-left: 5px;
    padding-right: 28px;
    border: 2px solid rgb(243, 243, 243);
    color: black;
    background-color: white;
}

.dropdownZending:focus {
    outline: none;
}

.inputSearchResults {
    position: relative;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ced4da;
    border-top: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


  .inputSearchResults table {
    width: 100%;
}

  .inputSearchResults td {
    padding: 0.5rem;
    border-bottom: 1px solid #e9ecef;
}

  .inputSearchResults tr:last-child td {
    border-bottom: none;
}

.customersSearch {
    position: relative;
}

.searchResults {
    position: relative;
}

.tableSearch {
    position: relative;
    background-color: white;
    width: 100%;
    padding: 10px;
    border-top: none !important;
    border: 2px solid rgb(243, 243, 243);
}

.tableSearch td {
    text-align: center;
    border: 2px solid rgb(189, 189, 189);
    border-top: none;
}

.selectDetails {
    border-radius: 10px;
    padding: 2.5px;
    padding-left: 5px;
    padding-right: 28px;
    border: 2px solid rgb(243, 243, 243);
    color: black;
    background-color: white;
}

.buttonsMake {
    display: flex;
    flex-direction: row;
}

.actionButtonsOrders:disabled{
    filter: brightness(0.8);
}

.activeSaleTypeStyle {
    background-color: rgb(221, 221, 221);
    border: 2px solid rgb(243, 243, 243);
}


.ordersEditLeftSide {
    width: 70%;
}

.orderTable > div {
    margin-bottom: 28px;
}

.ordersEditRightSide {
    width: 28%;
}

.ordersEditShowPriceTable {
    text-wrap: nowrap;
    width: 10%;
}

.ordersEditShowTaxTable {
    text-wrap: nowrap;
    width: 10%;
}

/* proccess orders */

.creditering-header {
    border-bottom: 1px solid #9C9C9C;
}

.proccessOrdersContainer {
    display: flex;
    justify-content: space-between;
}

.proccessOrdersLeftSide {
    width: 64%;
}

.proccessOrdersRightSide {
    width: 34%;
}

.orderTable th:nth-child(2) > div {
    text-align: left !important;
}

.orderTable {
    margin-left: 15px;
    margin-right: 15px;
}

.NewOrder {
    display: flex;
    flex-direction: column;
}

.bottom-line-overzicht {
    border-bottom: 1px solid #B5B5B5;
}

.subTotaal {
    margin-bottom: 10px;
}

.orderTable th:first-child {
    width: 15px !important;
}

.orderTable th:nth-child(2) {
    width: 300px !important;
}

.orderTable td > div {
    text-align: center;
}

.orderTable td:nth-child(2) > div {
    text-align: start;
}

.orderTable td:nth-child(4) > div > div {
    text-align: center;
}

/* email */
  
.email-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .email-header h2 {
    margin: 0;
  }  

/* orders edit media */
@media only screen and (max-width: 1730px) {
    .pagination {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

@media only screen and (max-width: 1595px) {
    .email-container > table > thead > tr > th:nth-child(3) {
        display: none;
    }
    .email-container > table > tbody > tr > td:nth-child(3) {
        display: none;
    }

    .package-status-container {
        padding-left: 10px !important;
    }

    .package-status-container::before {
        left: 40px !important;
    }
}

@media only screen and (max-width: 1315px) {
    .orderTable {
        width: fit-content !important;
    }

    .email-header {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }    

    .orderTableOverview {
        overflow-x: scroll;
    }

    .shipment-status-info-container {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        gap: 20px;
    }

    .shipment-status-info-container > div {
        width: 100% !important;
    }

    .packaging-info {
        padding-right: 0px !important;
        border-right: 0px !important;
    }
}

@media only screen and (max-width: 1050px) {
    .noteSubtotal {
        display: flex;
        flex-direction: column;
    }

    .parentNote {
        width: 100%;
        padding-right: 0px !important;
        margin-bottom: 1rem;
    }

    .parentSubTotaal {
        width: 100%;
    }
}

@media only screen and (max-width: 943px) {
    .ordersEditLeftSide {
        width: 100%;
    }

    .OrderEdit {
        width: 95%;
    }

    .ordersEditRightSide {
        width: 100%;
    }
    
    .ordersEditContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 768px) {
    .NewOrder {
        margin-left: 15px;
        margin-right: 15px;
    }


}

@media only screen and (max-width: 600px) { 
    .subTotaal {
        width: 100% !important;
    }

    .dropdownDetails {
        justify-content: space-between;
    }
}

@media only screen and (max-width: 540px) { 
    .subTotaal {
        width: 100% !important;
        margin-bottom: 10px;
    }

    .discountOrder {
        flex-direction: column;
    }

    .buttonswitch {
        width: 100%;
    }

    .buttonswitch > input:nth-child(1) {
        width: 100%;
    }

    .subTotal {
        display: flex;
        flex-direction: column-reverse;
    }

    .shippingCosts > .ShippingCostInput {
        width: 100% !important;
    }
    
    .shippingCosts {
        margin-bottom: 10px;
    }

    .dropdownZending {
        width: 100%;
    }

    .dropdownDetails {
        display: flex; 
        justify-content: space-between;
    }

    .sellLocation {
        width: 45%;
    }

    .ordersProcessing {
        width: 45%;
    }
    
}

@media only screen and (max-width: 400px) { 
    .orderEditScroll {
        width: 500px;
    }

    .dropdownDetails {
        display: flex; 
        flex-direction: column;
    }

    .sellLocation {
        width: 100%;
        margin-left: 0px !important;
    }

    .ordersProcessing {
        width: 100%;
        margin-right: 0px !important;
    }

    .orderOverview {
        width: 100% !important;
    }
}

/* proccess orders media */

@media only screen and (max-width: 943px) {
    .proccessOrdersLeftSide {
        width: 90%;
    }

    .proccessOrdersRightSide {
        width: 90%;
    }
    
    .proccessOrdersContainer {
        display: block;
    }
}


.verwerkIcon {
    background-color: #009fe3;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
}
.stockInput {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 2px;
    text-align: center;
    border-radius: 0.25rem 0 0 0.25rem;
    max-width: 65px;
    height: fit-content;
}
.stockInput2 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 0;
    width: 50px;
    border-left: 0;
    width: max-content;
    padding-right: 15px;
    height: fit-content;
}
.stockInput3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 0 0.25rem 0.25rem 0;
    width: 50px;
    border-left: 0;
    padding-right: 15px;
    width: max-content;
    height: fit-content;
}
.sendInput {
    padding-top: 0.5rem;
    height: fit-content;
    padding-left: 0.5rem;
    background-color: #e3e3e3;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
}
.packagesInput {
    align-self: center;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
    width: 80px;
    height: fit-content;
}

.packaging-info {
    padding-right: 15px;
}

.packaging-info > div > p {
    margin-bottom: 5px;
    font-size: 15px;
}

.package-status-container  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100%;
    padding-left: 50px;
}

.package-status-container::before {
    content: '';
    position: absolute;
    top: 25px;
    left: 100px;
    right: 45%;
    height: 4px;
    background-color: #009FE3;
    z-index: 1;
}

.package-status-container:has(> .packaging-status:nth-child(2) > .package-status-icon#completed)::before {
    right: 10%;
  }
  

.package-status-container > .packaging-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.package-status-container > .packaging-status > .package-status-icon {
    border: 2px solid #009FE3;
    background-color: white;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.package-status-container > .packaging-status > .package-status-icon#completed {
    border: 2px solid #009FE3;
    background-color: #009FE3;
}

.package-status-container > .packaging-status > .package-status-icon > img {
    width: 60%;
}

.package-status-container > .packaging-status > span {
    font-weight: 500;
}

.price {
    display: inline-block;
    text-align: left;
    min-width: max-content;
    width: fit-content;
    font-weight: bold;
}

.price-title {
    font-weight: bold;
}

.squareInput {
    border-radius: 5px;

}