.modal-content {
  background-color: transparent !important;
  border: none !important;
}

.modalTop {
  border-bottom: transparent !important;
  justify-content: end ;
  padding:  30px 30px;
  background-color: #FFFFFF ;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.modalCenter {
  display: flex !important;
  padding:  0px 30px;
  min-height: fit-content !important;
  background-color: #FFFFFF ;
}

.modalBottom {
  border-top: none !important;
  padding: 30px 30px;
  background-color: #FFFFFF ;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.closeModal {
  height: 40px;
  width: 40px;
  display: flex;
}

.modal-footer {
  display: block;
  justify-content: unset;
}


.emailModalHeader, .emailModalFooter {
  border-bottom: 1px solid #f9f9f9;
  background-color: #f9f9f9;
}.emailModalHeader {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}.emailModalFooter {
  border-bottom-left-radius: 15px;
  border-Bottom-right-radius: 15px;
}

