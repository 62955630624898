.container {
    text-align: center;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
}

.dropzone.focused {
    border-color: #2196f3;
}

.dropzone.accept {
    border-color: #00e676;
}

.dropzone.reject {
    border-color: #ff1744;
}

.tableLogs {
    width: 80%;
    display: flex;
    align-self: center;
    flex-direction: column;
}

.detailsImport {
    width: 80%;
    display: flex;
    align-self: center;
    flex-direction: column;
}

.status-text-unchanged {
    color: rgb(150, 150, 150);
}

.status-text-orange {
    color: rgb(255, 143, 51);
}

.status-text-green {
    color: rgb(55, 196, 0);
}

.status-text-red {
    color: rgb(214, 0, 0);
}

.import-export-table .tablePresetDataRow > td:nth-child(5) > div {
    text-align: left;
}

.file-card-container {
display: flex;
flex-wrap: wrap;
gap: 16px;
}

.file-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    padding: 10px;
    background-color: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    position: relative;
}

.file-card > img {
    filter: none !important;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.file-icon {
    color: #4caf50;
    margin-bottom: 8px;
}

.file-info p {
    margin: 0;
    width: 110px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    word-wrap: break-word;
}

.file-info small {
    color: #6b7280;
}

.file-rejection {
    background-color: #ffe6e6;
    border: 1px solid #f5c2c2;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 5px;
}

.file-rejection strong {
    display: block;
    font-size: 16px;
    color: #d32f2f;
}

.remove-btn {
    background: rgb(255, 104, 104);
    border: none;
    position: absolute;
    border-radius: 5px;
    top: 0px;
    right: 0px;
    cursor: pointer;
}

.import-page {
    min-width: 50%;
}

.import-export-table > div:first-child > div:nth-child(2) > div:first-child {
    background-color: #025a7a;
}

@keyframes pulse-red {
    0% { background-color: rgba(255, 104, 104, 0.2); }
    50% { background-color: rgba(255, 104, 104, 0.4); }
    100% { background-color: rgba(255, 104, 104, 0.2); }
}

.pulse-error {
    animation: pulse-red 2s ease-in-out infinite;
}
