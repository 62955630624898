.card {
    background-color: color;
    border: none;
    border-radius: 15px;
    height: 170px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.cardTitle {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.cardText {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}
