:root {
  --darkmodeSidebar: #3c4145;
  --darkmodeBackground: #0e151a;
  --darkmodeSmallText: #54595d;
  --darkmodeText: #ffffff;
  --darkmodePrimaryButton: #98c9f0;
  --darkmodeSecondaryButton: #3c4145;
  --darkmodeBlueLinkText: #bbd7ee;
}

/* Dark mode styling */

/* General Darkmode styles */
body.dark {
  background-color: var(--darkmodeBackground);
}

body.dark img, body.dark svg {
  filter: brightness(0) invert(1);
}

body.dark h1, body.dark h2, body.dark h3, body.dark h4, body.dark h5, body.dark h6, body.dark a, body.dark p, body.dark label, body.dark small {
  color: var(--darkmodeText);
}

body.dark .whiteBox {
  background-color: var(--darkmodeSidebar);
}

body.dark .standardInput {
  background-color: var(--darkmodeSmallText);
  border: 1px solid var(--darkmodeSmallText);
  color: var(--darkmodeText);
}

body.dark .standardDropdownArrow  {
 background-image: url(../Customer/AddCustomers/Icons/DropdownModalDarkmode.svg);
}

body.dark .SearchIcon  {
  background-image: url(../Customer/AddCustomers/Icons/SearchDark.png);
}



/* Navbar & Sidebar */
.sidebar.darkmode {
  background: var(--darkmodeSidebar);
}

body.dark .searchbarNav::placeholder {
  color: var(--darkmodeText);
}

body.dark .navbar {
  background-color: var(--darkmodeBackground);
}

body.dark .dropdown-content {
  background-color: var(--darkmodeSidebar);
}

body.dark .logOutText  {
  color: var(--darkmodeText) !important;
}


/* Dashboard */
body.dark .mainBox {
  background-color: var(--darkmodeBackground);
}

body.dark .boxes {
  background-color: var(--darkmodeSidebar);
}

body.dark .appstoreBox, body.dark .appstoreBox p  {
  background-color: var(--darkmodeSmallText);
}

/* Table */

body.dark .tablePresetBackground {
  background-color: var(--darkmodeSidebar);
  color: var(--darkmodeText);
}

body.dark .tablePresetDataRow:hover {
  background-color: var(--darkmodeSmallText);
}

body.dark .selectPresetStyle {
  background-color: var(--darkmodeSidebar);
  color: var(--darkmodeText);
  transition: none;
}

body.dark .optionsContainerStyle.open {
  background-color: var(--darkmodeSidebar);
  color: var(--darkmodeText);
}

body.dark .optionsContainerStyle.closed {
  background-color: var(--darkmodeSidebar);
  color: var(--darkmodeText);
}

body.dark .extraButtonsLeft {
  background-color: var(--darkmodeSidebar);
  color: var(--darkmodeText);
}

body.dark .tableSearchBar {
  background-color: var(--darkmodeSidebar);
  color: var(--darkmodeText);
}

body.dark .tableSearchBar::placeholder {
  color: var(--darkmodeText);
}

body.dark .page-sizer {
  background-color: var(--darkmodeSidebar);
  color: var(--darkmodeText);
  background-image:  url('../Customer/AddCustomers/Icons/dropdownArrowDarkmode.svg');
}

body.dark .page-display {
  color: var(--darkmodeText);
}

/* Modal darkmode style */

body.dark .modal-content > div {
  background-color: var(--darkmodeSidebar);
}

body.dark .colorName {
  color: var(--darkmodeText);
}

body.dark .inputFieldAddCustomer {
  background-color: var(--darkmodeSmallText);
  border: 1px solid var(--darkmodeSmallText);
  color: var(--darkmodeText);
}

body.dark .diffDateIcon {
  background-image: url(../Customer/AddCustomers/Icons/calendardark.png);
  background-size: 19px;
}

body.dark .dropdownsNewCustomers {
  background-image: url(../Customer/AddCustomers/Icons/dropdownDarkmodeNewCustomer.svg);
}

/* Customer Hub */

body.dark .widthOverviewSmallboxes > .generalInfoBox > div > div {
 color: var(--darkmodeText);
}

body.dark .darkBlueLinkText {
  color: var(--darkmodeBlueLinkText);
}

body.dark ul.nav.nav-tabs {
  background-color: var(--darkmodeSidebar);
}

body.dark .nav-tabs .nav-link.active {
  background-color: var(--darkmodeSidebar);
  border: 1px solid var(--darkmodeSidebar);
  border-bottom: 4px solid #009fe3;
}

body.dark .nav-tabs .nav-link:hover {
  background-color: var(--darkmodeSidebar);
  border: 1px solid var(--darkmodeSidebar);
  border-bottom: 4px solid #009fe3;
}

body.dark .nav-tabs .nav-link {
  background-color: var(--darkmodeSidebar);
  border: 1px solid var(--darkmodeSidebar);
}

body.dark .nav-tabs .nav-link span {
  color: var(--darkmodeText);
}

body.dark .nav-tabs .nav-link.active > span  {
  color: var(--darkmodeText);
}

body.dark .infoIcons {
  filter: none;
}

body.dark .customerInfoBox > div:nth-child(2) > div > div > div > div {
  color: var(--darkmodeText);
}

body.dark .customerInfoBox > div:nth-child(2) > div > div > div {
  color: var(--darkmodeText);
}

body.dark .customerInfoBox > div:nth-child(2) > div {
  color: var(--darkmodeText);
}

body.dark .tablesCustomerEdit > div:first-child > div:first-child > div:first-child {
  background-color: var(--darkmodeSmallText);
}

body.dark .tabContent > div > div > div:nth-of-type(3) > div:first-child > select {
  background-color: var(--darkmodeSmallText);
}

body.dark .adressOverviewTab .tableSearchBar {
  background-color: var(--darkmodeSmallText);
  color: var(--darkmodeText);
}

body.dark .ordersOverviewTab .tableSearchBar {
  background-color: var(--darkmodeSmallText);
  color: var(--darkmodeText);
}

body.dark .QuotationOverviewTab .tableSearchBar {
  background-color: var(--darkmodeSmallText);
  color: var(--darkmodeText);
}

body.dark .InvoiceOverviewTab .tableSearchBar {
  background-color: var(--darkmodeSmallText);
  color: var(--darkmodeText);
}

body.dark .loyaltyTable > div > div:nth-child(3) > div:first-child > select {
  background-color: var(--darkmodeSmallText);
}

body.dark .notesTable > div > div:nth-child(3) > div:first-child > select {
  background-color: var(--darkmodeSmallText);
}

body.dark .arrowCircleRight {
  filter: none;
}


/* Darkmode styling */