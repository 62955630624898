.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.editProfilePicture {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.editProfilePicture > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profilePictureLoadingBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
}

.imageUpload {
    display: none;
}

.uploadImageBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.btn-techdog {
    background-color: #0586D3;
    color: white;
}

.btn-techdog:hover {
    background-color: #39b6ff;
    color: white;
}

.innerBox {
    padding: 0.5rem;
}

.fileDropBox {
    background-color: rgba(0, 0, 0, 0.05);
    border: dotted #0586D3 4px;
    border-radius: 0.75rem;
    height: 18rem;
}

.modalChangePassword {
    background-color: white;
    padding: 2rem;
}

.modalContent {
    background-color: white;
    padding-top: 0;
}

.modalFooter {
    background-color: white;
    padding: 1rem;
}

.deletePfp {
    width: 83.5%;
    border-radius: 2rem;
    box-shadow: 0 0 0 0.7px #dc3545;
}

.deletePfp:hover {
    width: 83.5%;
    border-radius: 2rem;
    box-shadow: 0 0 0 0.7px #bb2d3d;
}