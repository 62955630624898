.tableBlueprints tbody td:last-child > div {
  white-space: nowrap;
  width: 10px	!important;
}

.tableBlueprints thead th:last-child {
  white-space: nowrap;
  width: 10px	!important;
}

.tableBlueprints > div:nth-child(1) {
  margin-bottom: 10px;
}

.tableBlueprints th > div {
  text-align: left !important;
}

@media only screen and (max-width: 600px) {
  .tableBlueprints {
    width: fit-content;
  }

  .blueprintsOverview {
    overflow-x: scroll;
  }
}