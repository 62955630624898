.backgroundGradient {
  background: linear-gradient(180deg, rgba(0,159,227,1) 0%, rgba(2,90,122,1) 100%);
  height: 100vh;
  width: 100vw;
}

.inputsUserCredentials {
  border-radius: 20px !important;
  width: 700px;
  height: 40px;
  padding: 0px 15px !important;
}

.logoLogin {
  scale: .7;
}

.loginButton {
  background-color: #009FE3;
  height: 40px;
  color: white;
}

.loginButton:hover, .loginButton:focus, .loginButton:active {
  background-color: #0090ce !important;
  color: white !important;
  outline: none;
  border: #00000000;
}

.forgotPassword {
  color: #8abccf;
  cursor: pointer;
  width: fit-content;
  text-decoration: underline;
}