
@media only screen and (max-width: 1750px) {
  .parentCompanyInfoColumn {
    width: 75% !important;
  }
}

@media only screen and (max-width: 1400px) {
  .parentCompanyInfoColumn {
    width: 85% !important;
  }
}

@media only screen and (max-width: 1135px) {
  .parentCompanyInfoColumn {
    width: 100% !important;
  }
}