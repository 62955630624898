.card-shadow {
    box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.2);
}

.customer-list-container {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    overflow: hidden;
  }
  
  .customer-list {
    border: 2px solid #dee2e6;
    border-top: none;
  }

  .result-header th {
    background-color: #f8f9fa;
    border: 2px solid #dee2e6;
    border-top: none;
    font-weight: 600;
    text-align: left;
  }

  .customer-item {
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .customer-item:hover {
    background-color: #f1f3f5;
  }

  td, th {
    padding: 0.75rem;
  }

  .process-product-icon {
    background-color: #EFEFEF;
    padding: 50px;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
  }

  .bottom-border-line {
    border-bottom: 1px solid #B0B0B0;
  }

  .bottom-border-line-light {
    border-bottom: 1px solid #B0B0B050;
  }

  .modalCenter {
    display: flex;
    flex-direction: column;
}

.input-button-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 16px;
}

.inputWithInInput {
    width: 100%;
    padding-right: 80px;
    height: 40px;
}

.placeholderStyle {
    font-size: 14px;
}

.btn-in-input {
  border-radius: 0px 5% 5px 0px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 16px;
  background-color: #009fe3;
  border: none;
  color: white;
  cursor: pointer;
  width: 150px;
}

.order-truck-icon {
  width: 25px;
  height: 25px;
}

.email-main-container {
  padding-left: 45px;
  padding-right: 45px;
}