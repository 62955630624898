.categoryfilter .categoryCheckbox {
    padding-left: 0px !important;
}
.categoryfilter .subcategoryItem {
    padding-left: 30px !important;
}
.categoryfilter {
    max-height: 240px;
    overflow-y: auto;
}
.categoryfilter .categoryCheckbox:first-of-type {
    
    padding-top: 0 !important;
    margin-top: 0 !important;
}

.categoryfilter .subcategoryItem:first-of-type {
    padding-left: 0 !important;
}

.categoryfilter {
    text-wrap: nowrap;
}
.categoryfilter img {
    margin-top: 6px;
    margin-right: 0;
}

.standardInput.open {
    border-radius: 20px 20px 0px 0px !important;
}
.categoryfilter.open {
    border-radius: 0px 20px 20px 20px !important;
    border: 1px solid #d6d6d6;
    border-top: 0;
}