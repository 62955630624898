.sidebar {
  position: fixed;
  height: 100vh;
  background: var(--GradientBlue);
  position: fixed;
  width: 270px;
  width: 270px;
  border-top-right-radius: 50px;
  /* transition: width 0.5s; */
}

.alignCenter {
display: flex;
align-items: center;
}

.iconSidebarCenter {
height: 35px;
margin-right: 10px;
}

.logo {
  max-width: 80%;    
  max-height: 20vh !important;
  position: relative;
  top: -45px;
}

.collapseText {
  font-size: 16px;
}

.modeToggleIcons {
  height: 20px;
  width: 20px;
}

.modeToggleIconsSun {
  height: 25px;
  width: 25px;
}


.switchMode {
  position: relative;
  display: inline-block;
  min-width: 25px;
  height: 16px;
}

.switchMode input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderMode {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b4cbdf;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderMode:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 2px;
  top: 16%;
  background-color: #025a7a;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .sliderMode {
  background-color: white;
}

input:focus + .sliderMode {
  box-shadow: 0 0 1px white;
}

input:checked + .sliderMode:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(10px);
}

/* Rounded sliders */
.sliderMode.round {
  border-radius: 34px;
  border: 1px solid #efefef;

}

.sliderMode.round:before {
  border-radius: 50%;
}


.iconsSidebar {
  width: 23px;
  height: 23px;
  filter: brightness(0) invert(1);
}

.icon {
  width: 20px;
  height: 20px;
  color: rgb(0, 0, 0);
  color: rgb(0, 0, 0);
}

.icon-text {
  color: white;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0 !important;
  /* overflow: hidden; */
  width: fit-content;
  /* transition: width 0.5s ease; */
}

.inactive-icon-text {
  color: rgba(255, 255, 255, 0.405);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0 !important;
  /* overflow: hidden; */
  width: fit-content;
  /* transition: width 0.5s ease; */
}

.inactive-icon img {
  opacity: 0.405;
}

.sidebar-divider {
  width: 80%;
  height: 1px;
  color: white;
  text-align: center;
  background-color: #ccc;
  margin: 15px auto;
}
 
.pageIndicator {
  font-weight: 600;
  color: black;
}


/* nav > div:nth-child(2) > div:nth-child(8) {
  position: absolute;
  bottom: 15px;
  width: 100%;
}   */

.lastSidbarItemPlacment:nth-child(8) {
  bottom: 10%;
}

.burgerSidebar > div:nth-child(2) > div:nth-child(8) {
  position: absolute;
  bottom: 4px !important;
  width: 100%;
}   

nav > div:nth-child(2) > div:nth-child(8) > a > p:nth-child(2) {
  color: white;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  line-height: 15px;
  position: relative;
  bottom: 2px;
}  

.optionsSidebarProducts {
  position: relative;
  bottom: 10px;
  margin-left: 15px;
  cursor: pointer;
  font-size: small;
  border-left: 2px solid rgb(238, 238, 238);
  padding-left: 15px;
  color: black;
}

.pageIndicator {
  background-color: #e0e0e0c4;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width:  fit-content;
}

.subPages {
  border-left: 4px solid #e0e0e0c4 !important;
}

.subPages:hover {
  border-left: 4px solid var(--Blue) !important;
  color: var(--Blue) !important;
}

.optionsSidebarProducts:hover {
  color: rgb(94, 94, 94);
}

/* Hamburger Button Styling */
.hamburger-button {
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* BurgerSidebar Styling */
.burgerSidebar {
  position: fixed;
  top: 0;
  z-index: 9;
  left: -250px;
  width: 250px;
  height: 100%;
  border-right: 2px solid var(--LightGray);
  background: var(--White);
  transition: left 0.3s;
  color: var(--White);
}

.burgerSidebar.open {
  left: 0;
}

.iconsCenter {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: fit-content;
}

/* Sidebar List Styling */
.burgerSidebar ul {
  padding: 20px 0;
  list-style: none;
  padding-left: 10px;
}

.burgerSidebar li {
  padding: 8px 0;
}

.dropdownSidebar {
  position: absolute;
  left: 100%;
  height: fit-content;
  background-color: var(--White);
  min-width: 160px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 11px 8px 16px -5px rgba(0,0,0,0.2);
  opacity: 0; /* Start with 0 opacity */
  visibility: hidden; /* Make it invisible */
  transition: visibility 0s linear 0.1s, opacity 0.1s linear; /* Add transition with delay */
}

.icon-container:hover .dropdownSidebar {
  visibility: visible; /* Make it visible when hovered */
  opacity: 1; /* Fade to full opacity */
  transition-delay: 0.3s; /* Remove delay for visibility */
}

.textColorSidebarDropdown {
  color: gray !important;
}

.textColorSidebarDropdown:hover {
  color: var(--Blue) !important;
  border-left: 5px solid var(--Blue);
}

.icon-container:hover {
  background-color: var(--Blue);
  border-radius: 20px;
}

.inactive-icon-container:hover {
  /* background-color: #009fe350; */
  cursor: not-allowed;
  border-radius: 20px;
}

.icon-container {
  width: 200px;
}

.inactive-icon-container {
  color: lightslategray !important;
  width: 200px;
}

.collapsed .inactive-icon-container {
  color: lightslategray !important;
  width: fit-content;
}

.activeSidebarItem {
  background-color: var(--Blue);
  border-radius: 500px;
}

.dropdownSidebarContent {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.collapsed  .sidebar{
  width: 100px;
}

.collapsed .collapseText {
  display: none;
}


.collapsed .icon-text {
  /* animation: collapseIconText 0.5s forwards;  */
  overflow: hidden;
  width: 0px; 
  opacity: 0; 
}

.collapsed .inactive-icon-text {
  /* animation: collapseIconText 0.5s forwards;  */
  overflow: hidden;
  width: 0px; 
  opacity: 0; 
  color: #e300a3;
}


.sidebar:not(.collapsed) .icon-text {
  /* animation: expandIconText 0.5s forwards;  */
  width: 100px; 
  opacity: 1; 
  display: inline-block;
}

.sidebar:not(.collapsed) .icon-container {
  /* animation: expandBackgroundIconText 0.5s forwards;  */
  /* width: 100px;  */
  opacity: 1; 
  display: inline-block; 
}

/* @keyframes collapseIconText {
  0% {
    width: 100px; 
    opacity: 1;
  }
  100% {
    width: 0px;
    opacity: 0;
  }
}

@keyframes expandIconText {
  0% {
    width: 0px;
    opacity: 0;
  }
  100% {
    width: 100px; 
    opacity: 1;
  }
}

@keyframes expandBackgroundIconText {
  0% {
    width: 0px;
  }
  100% {
    width: 200px; 
  }
} */


.collapsed .icon-container {
  width: fit-content !important;
  /* transition: width 0.5s ease; */
}

.collapsed .logo {
  top: 0px;
  /* transition: top 0.5s ease; */
}

.collapsed .iconSidebarCenter {
  margin-right: 0px;
  /* transition: margin-right 0.5s ease; */
}

.collapsed .mainbox {
  width: calc(100% - 100px) !important;
  /* transition: width 0.5s ease; */
}

.SortingIcon {
  height: 10px;
}

.SortingHeaderContainer {
  height: 25px;
}


@media only screen and (max-width: 768px) {

  .iconsSidebar {
      width: 35px !important;
      height: 35px !important;
  }

  .sidebar {
      width: 70px !important;
  }

  .optionsSidebarProducts {
      position: relative;
      bottom: 5px !important;
      margin-left: 15px;
      right: 18px;
      cursor: pointer;
      font-size: small;
      border-left: 2px solid rgb(238, 238, 238);
      padding-left: 15px;
      color: black;
      font-size: medium;
  }

}

@media only screen and (max-width: 1457px) {
  .sidebar {
    z-index: 20;
  }
}