.text-subTitle {
    font-size: 1.5rem;
}

.text-infoKey {
    font-size: 1.1rem;
}

.text-infoValue {
    font-size: 1rem;
}

.infoData {
    padding: 1rem;
    width: 24rem;
    display: grid;
    grid-template-columns: 20rem 20rem;
    row-gap: 1rem;
}