.tablePresetBackground {
  background-color: white;
  padding: 15px 45px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.tablePresetBackground > table {
  width: 100%;
}

.page-display {
  cursor: pointer !important;
  align-self: center;
  margin-left: 7px;
  font-weight: 500;
  color: #9d9d9d;
}

.tablePresetDataRow:hover {
  transition: .1s;
  background-color: #f2f0f0;
}

.tablePresetDataRow {
  border-bottom: 1px solid #b8b8b890;
  height: 50px;
}

.tablePresetDataCol:first-child {
  padding-right: 0px !important;
}

.tableRow {
  border-bottom: 2px solid rgb(189, 189, 189);
}

.header-filter {
  cursor: default;
  font-weight: 600 !important;
}

tbody > tr {
  font-size: 15px;
  font-weight: 500;
}

.selectPresetStyle .dropdownIndicator {
  position: relative;
  top: 0px;
  left: 5px;
}

.selectPresetStyle.clicked .dropdownIndicator {
  animation: spin 0.5s forwards;
}

.selectPresetStyle:not(.clicked) .dropdownIndicator {
  animation: spin-back 0.5s forwards;
}

/* tr:last-child > td, tr:last-child {
  padding-bottom: 0px;
} */

.tablePresetBackground thead > tr {
  border-bottom: 2px solid #b8b8b890;
}

.sortingIcon {
  width: 9px;
  height: 13px;
}

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  bottom: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #e4e4e4;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.tablePresetHeader:hover {
  cursor: pointer;
}

/* When the checkbox is checked, add a blue background */
/* .checkbox-container input:checked ~ .checkmark { */
  /* background-color: #2196F3; */
  /* border: none; */
/* } */

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
  
  /* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 8px;
  top: 2px;
  width: 8px;
  height: 16px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg) scale(1.3);
}

.btn-custom {
  padding: 10px;
  border-radius: 200px;
  font-weight: 600;
  display: flex;
  align-items: center;
  /* width: 100px; */
  height: 40px;
  /* margin-right: 0px !important; */
  margin: 20px;
  background-color: white;
  text-decoration: none;
  color: black;
  height: 40px;
  outline: none !important;
}

.tableSearchBarContainer {
  align-self: center;
  padding: 10px;
  border-radius: 200px;
  font-weight: 600;
  display: flex;
  align-items: center;
  /* width: 100px; */
  height: 40px;
  /* margin-right: 0px !important; */
  background-color: white;
  text-decoration: none;
  color: black;
  height: 40px;
  outline: none !important;

}

.tableSearchBar::placeholder {
  color: black;
  font-weight: 600;
}

.tableSearchBar, .tableSearchBar:focus {
  outline: none;
  border: none;
}

.tableSearchBar, .tableSearchBarContainer {
  width: 400px;
}

.tableSearchBar::placeholder {
  color: black;
  font-weight: 600;
}

/* .searchInputTable, .searchInputTable:focus {
  outline: none !important;
  border: none !important;
  padding: 10px;
  padding-right: 25px;
  border-radius: 200px;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 40px;
  margin: 20px;
  margin-right: 0px !important;
  background-color: white;
  color: black;
  outline: none !important;
} */

.searchIconTableSearch {
  display: flex;
  position: relative;
  top: 39% !important;
  right: 25px;
}

.btn-custom2, .btn-custom2:focus {
  border: 1px solid #9d9d9d;
  padding: 10px;
  /* width: 100px; */
  height: 40px;
  font-weight: 600;

  align-items: center !important;
  padding-bottom: 10px !important;
  display: flex !important;
  margin-left: 0px !important;
  margin: 20px;
  background-color: white;
  text-decoration: none;
  color: black;
  outline: none;
}

.btn-custom2:hover {
  transition: 200ms;
  background-color: rgb(216, 216, 216);
}


.btn:disabled {
  /* background-color: black; */
  color: #c5c5c5;
}

.page-sizer {
  display: inline-block;
  position: relative;
  height: 35px;
  border-radius: 20px;
  border: 0px solid transparent;
  padding-left: 20px;
  width: 75px;
  align-content: center;
  align-self: center;
  color: black;
  background-color: white;
  font-weight: 600;
  appearance: none; /* Removes default browser styling */
  -webkit-appearance: none; /* Specifically for WebKit browsers */
  background-image: url('../Customer/DropdownArrow.svg'); /* Your custom arrow image */
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%; /* Moves the arrow right */
  background-size: 10px;
}

.page-sizer::-ms-expand {
  display: none; /* Hides the default arrow in IE */
}


.page-sizer:focus {
  outline: none;
}

.pageBackNext {
  background-color: #009fe3 !important;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toFirstLastPage {
  background-color: #025a7a !important;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageNumBlocks {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionOptions {
  width: 100px;
  margin: 0px;
  margin-right: 10px !important;
  align-self: center;
}

.filterButton {
  margin: 0px;
  align-self: center;
}

.newButtons:nth-child(1) {
  background-color: #009fe3;
  color: white;
}

.newButtons:nth-child(3) {
  background-color: #009fe3;
  color: white;
}

.newButtons:hover {
  background-color: #00749e !important;
  color: white !important;
}

.newButtons:nth-child(2) {
   background-color: #009fe3;
   color: white;
}

.page-display {
  color: rgb(61, 60, 60);
}

.btn-custom3 {
  background-color: white;
  height: 40px;
  color: #9d9d9d;
  display: flex;
  align-self: center;
  border: 1px solid #9d9d9d;
  padding: 10px;
  align-items: center;
}

.btn-custom3 > input:focus, .btn-custom3 > input {
  outline: none;
  font-weight: 600 !important;
  background-color: white;
}
  
.dropdownStyle {
  background-color: #dbdbdb;
  width: 30vw !important;
}

.selectPresetStyle, .selectPresetStyle:focus {
  user-select: none;
  display: flex;
  align-items: center;
  border-radius: 50px;
  height: 40px;
  background-color: white;
  border: 0px;
  padding: 23px 30px;
  transition: .2s ease-in-out;
}

.selectPresetStyle.open {
  outline: none;
  border-radius: 20px 20px 0px 0px !important;
}

/* dropdown styles */
.optionsContainerStyle {
  width: 200px;
  position: absolute;
  background-color: white;
  border-radius: 0px 20px 20px 20px;
  z-index: 3;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  transform-origin: top; 
  transform: scaleY(0);
  overflow-y: scroll;
  max-height: 200px;
}

.optionsContainerStyle::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.optionsContainerStyle.open {
  transition: .2s ease-in-out;
  animation: dropdownSlideIn 0.2s forwards;
}
.optionsContainerStyle:not(.open) {
  animation: dropdownSlideOut 0.2s forwards;
}

.optionStyle {
  user-select: none;
  border-radius: 0px;
  padding: 10px;
  transition: .2s;
}
.optionStyle:last-child:hover,  .optionStyle:last-child {
  border-radius: 0px 0px 20px 20px;
}
.optionStyle:first-child:hover,  .optionStyle:first-child {
  border-radius: 0px 20px 0px 0px;
}
/* end dropdown styles */

.optionStyle:hover {
  background-color: #fafafa;
  color: black;
  cursor: pointer;
}

.tablePresetHeaderRow:first-child {
  width: 20px !important;
  padding-left: 18px !important;
  padding-right: 0px !important;
}

.tableLoadingScreen {
  background-color: rgb(202, 202, 202);
  color: var(--White);
  font-size: 20px;
  border-radius: 30px;
  margin-bottom: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableLoadingScreen.l {
  height: 400px;
  font-size: 40px;
}

.tableLoadingScreen.m {
  height: 350px;
  font-size: 30px;
}

.tableLoadingScreen.s {
  height: 200px;
  font-size: 20px;
}

.tableLoadingScreen.xs {
  height: 100px;
  font-size: 20px;
}

.buttonPresetStyle {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  border-radius: 50px;
  height: 30px;
  background-color: white;
  border: 0px;
  font-weight: 500;
  padding: 23px;
  padding-left: 40px;
  padding-right: 40px;
}

.buttonPresetStyle:hover, .selectPresetStyle:hover {
  cursor: pointer;
  background-color: #fafafa;
  transition: .2s;
}


.tablePresetDataRow.dragging {
  background-color: #f0f0f0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
  width: 100%;
  height: fit-content !important;
  padding: 5px 0px;
}

.tablePresetDataRow.dragging td:nth-child(1) {
  width: 12.5vw;
}
.tablePresetDataRow.dragging td:nth-child(2) {
  width: 38.3vw;
}
.tablePresetDataRow.dragging td:nth-child(3) {
  width: 25vw;
  /* text-wrap: nowrap; */
}
.tablePresetDataRow.dragging td:nth-child(4) {
  width: 25vw;
}


@media only screen and (max-width: 600px) {

  .tablePreset {
    width: 300px !important;
    overflow-x: scroll !important;
  }
}
@media only screen and (max-width: 1400px) {
  .mainContainer {
    display: flex;
    flex-direction: column-reverse;
  }
}

@keyframes dropdownSlideIn {
  0% {
      transform: scaleY(0);
      visibility: hidden;
  }
  100% {
      transform: scaleY(1);
      visibility: visible;
  }
}

@keyframes dropdownSlideOut {
  0% {
      transform: scaleY(1);
      visibility: visible;
  }
  100% {
      transform: scaleY(0);
      visibility: hidden;
  }
}