.tab-title {
  color: rgb(0, 0, 0);
  font-size: medium;  /* You can change this to your preferred color */
}

.tab-title.active {
  color: rgb(0, 0, 0);  /* You can change this to your preferred color */
}

.fontSize15 {
  font-size: 15px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #fff;
  border: 1px solid white;
  border-bottom: 4px solid #009fe3;
  position: relative;
  top: 3px;
}

.BirthdateConsumer {
  flex: 1;
}

/* HTML: <div class="loader"></div> */
.loader {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 30px;
  animation: l1 1s linear infinite alternate;
}
.loader:before {
  content:"Loading..."
}
@keyframes l1 {to{opacity: 0}}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid rgb(255, 255, 255);
  margin: 2px;
  margin-bottom: 0px;
  /* margin-left: 15px;
  margin-right: 15px; */
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  top: 3px;
  min-width: fit-content; 
  white-space: nowrap;
  width: fit-content; 
}

.nav-tabs > li:first-child > .nav-link {
  margin-left: 0px !important;
}

.nav-tabs > li:last-child > .nav-link {
  margin-right: 0px !important;
}

.componentMarginTop2_5 {
  margin-top: 2.5rem;
}

.tabsOverviewPadding {
  padding-bottom: 32px;
}

.widthOverviewSmallboxes {
  flex: 0 0 auto;
  width: 23%;
  height: fit-content;
}

.widthOverviewLargeboxes {
  flex: 0 0 auto;
  width: 75%;
  height: 633px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.convertToBoxOverview {
  height: 91px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.convertToBusinessButton {
  background-color: #009fe3;
  height: fit-content;
  border-radius: 25px;
  color: white;
  padding: 6px 45px;
  font-weight: 600;
}

.convertToBusinessButton:focus, .convertToBusinessButton:hover, .convertToBusinessButton:active {
  background-color: #0090ce !important;
  color: white !important;
  border: #0090ce;
}

.labelGenerelInfoitems {
  color: #025a7a;
}

.convertToAndNotes {
 width: 23%;
}

.latestNotes {
  min-height: fit-content;
  padding: 24px 30px;
  min-height: fit-content;
}

.latestNotesQuotation {
  height: fit-content;
  padding: 24px 30px;
}

.heightGeneralInfoInputs {
  height: 45px;
  border-radius: 40px;
}

.darkBlueLinkText {
  color:#216e8a;
}

.noteTitle {
  color: #009fe3;
  margin-bottom: 0px;
}

.heightLargeboxesOverview {
  height: 29%;
  padding: 24px 30px;
}

.HeightOverviewInfo {
  height: fit-content;
  margin-bottom: 20PX;
}

.generalInfoBox {
  min-height: fit-content;
  overflow-y: scroll;
  padding: 20px 30px;
}

.adressInfoBox {
  min-height: fit-content;
}

.arrowCircleRight {
  height: 13px;
  width: 13px;
}

.widthLargeBoxitems {
  width: 33%;
}

.tableContacts > .tablePresetBackground {
  padding: 0;
}
.tableContacts > .mainContainer {
  margin-bottom: 0 !important;
}
.loyaltyTable thead > tr > th {
  width: 17% !important;
}

.loyaltyTable thead > tr > th:nth-child(4) {
  width: 30% !important;
}

.ordersOverviewTab .tablesCustomerEdit .tablePresetBackground th:not(:first-child) {
  padding: 0 !important;
}
.ordersOverviewTab .tablesCustomerEdit .tablePresetBackground {
  padding: 0 !important;
}
ul.nav.nav-tabs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding-bottom: 0px;
  margin-right: 0px !important;
  background-color: #ffffff;
  border-bottom: 2px solid #b8b8b8;
  gap: 0px; /* Voeg ruimte tussen de tabs toe */
  justify-content: flex-start; /* Plaats tabs links zonder grote ruimte */
}

ul.nav.nav-tabs li {
  flex: 1 1 auto; /* Verdeel tabs gelijkmatig over de rij */
}


.nav-link:disabled > span {
  color: #b8b8b8;
}


.nav-tabs .nav-link:hover {
  border-bottom: none;  /* Change to your preferred border color */
  position: relative;
  border: 1px solid rgb(255, 255, 255);
  border-bottom: 4px solid #009fe3;
  top: 3px;
}

.customerInfoBox {
  width: 30% !important;
  height: fit-content !important;
}
.customerInfoBoxWithNotes {
  width: 100% !important;
  height: fit-content !important;
}

.adressOverviewTab > div > div:nth-child(2) > table > thead  th:last-child{
 width: 20px;
}

.infoIcons {
  padding: 18px;
  height: 70px;
  width: 70px;
  align-self: center;
  margin-right: 15px;
  background-color: #009fe3;
}

.downloadImg {
  display: flex;
  align-self: center;
  height: 20px;
  width: 20px;
}

.textInfoCustomer {
  width: 75%;
  margin-bottom: 0px !important;
  word-wrap: normal;
  font-size: 15px;
}

.trashImg {
  width: auto;
  height: 15px;
  cursor: pointer;
}

/* .tabContent > div > div > div > div:nth-of-type(2) > div {
  background-color: #009fe3;
  color: white;
} */

.notesTable tbody > tr > td:nth-child(4),
.notesTable tbody > tr > td:nth-child(5),
.notesTable tbody > tr > td:nth-child(6) {
  padding: 0px 0px !important;
}

.tabContent > div > div > div > div:nth-of-type(2) > div:hover {
  background-color: #0090ce;
  color: white;
}

.tabContent > div > div > div:nth-of-type(3) > div:first-child > select {
  background-color: #f8f8f8;
}

.tablesCustomerEdit > div:first-child > div:first-child > div:first-child {
  background-color: #f8f8f8;
}

.tablesCustomerEdit > div:first-child > div:nth-child(2) > div {
  padding: 21px 0px;
  width: 200px;
}

.tablesCustomerEdit > div:first-child > div:first-child  > input {
  background-color: #f8f8f8;
}

.tablesCustomerEdit > div:first-child > div:first-child > input::placeholder {
  color: black;
  font-weight: 600;
}

.QuotationOverviewTab > div > div:nth-child(2) > table > tbody  td:last-child {
  width: 20px;
  padding: 1rem 10px !important;
 }

.QuotationOverviewTab > div > div:nth-child(2) > table > thead  th:last-child {
  width: 20px;
  padding: 1rem 10px !important;
 }

 .QuotationOverviewTab > div > div:nth-child(2) > table > tbody  td:nth-child(5){
  width: 20px;
  padding-right: 10px !important;
 }

.QuotationOverviewTab > div > div:nth-child(2) > table > thead  th:nth-child(5) {
  width: 20px;
  padding-right: 10px !important;
 }

/* .tabContent > div > div > div > div:nth-of-type(1) > div:nth-child(1) {
  border: 2px solid #f3f3f3;
} */

/* .tabContent > div > div > div > div:nth-of-type(1) > div:nth-child(2) {
  border: 2px solid #f3f3f3;
} */

/* .tabContent > div > div > div:nth-child(3) > div > div {
  border: 2px solid #ffffff !important;
}

.tabContent > div > div > div:nth-child(3) > div > div {
  border: 2px solid #ffffff !important;
}

.tabContent > div > div > div:nth-child(3) > div:nth-child(2) > button {
  border: 2px solid #f3f3f3 !important;
}

.tabContent > div > div > div:nth-child(2) {
  border: 2px solid #f3f3f3 !important;
  border-radius: 10px;
} */

.buttonPresetStyle {
  margin-bottom: 0px !important;
}

.tableInvoice div:nth-child(1) {
  margin-bottom: 5px;
}

.tableQuotation div:nth-child(1) {
  margin-bottom: 5px;
}

.tableQuotation > div:nth-child(2) > table > thead > tr > th:first-child > div {
  text-align: start !important;
}

.tableQuotation > div:nth-child(2) > table > tbody > tr > td:first-child > div {
  text-align: start !important;
}

.tableQuotation > div:nth-child(2) > table > tbody > tr > td > div {
  text-align: center !important;
}

.tableQuotation > div:nth-child(2) > table > thead > tr > th:first-child {
  width: 55%;
}

.tableQuotation > div:nth-child(2) > table > tbody > tr > td:first-child {
  width: 55%;
}

.tableInvoice > div:nth-child(2) > table > thead > tr > th:first-child > div {
  text-align: start !important;
}

.tableInvoice > div:nth-child(2) > table > tbody > tr > td:first-child > div {
  text-align: start !important;
}

.tableInvoice > div:nth-child(2) > table > tbody > tr > td > div {
  text-align: center !important;
}

.tableInvoice > div:nth-child(2) > table > thead > tr > th:first-child {
  width: 55%;
}

.tableInvoice > div:nth-child(2) > table > tbody > tr > td:first-child {
  width: 55%;
}

/* tijdelijk */

.tableQuotation > div:nth-child(2) > table > tbody > tr > td{
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.tableQuotation > div:nth-child(2) > table > tbody > tr:nth-child(2) > td:nth-child(3) > div > p {
  display: none !important;
}

.tableQuotation > div:nth-child(2) > table > tbody > tr:nth-child(2) > td:nth-child(3) > div:after {
  content: "Geaccepteerd" !important;
  color: #79b893 !important;
}

.tableInvoice > div:nth-child(2) > table > tbody > tr:nth-child(2) > td:nth-child(4) > div > p {
  display: none !important;
}

.tableInvoice > div:nth-child(2) > table > tbody > tr:nth-child(2) > td:nth-child(4) > div:after {
  content: "Betaald" !important;
  color: #79b893 !important;
}

.notesTable > div > div:nth-child(2) {
   padding: 15px 15px;
}

.notesTable > div > div:nth-child(3) > div:first-child > select{
  background-color: #f8f8f8;
}

.notesTable > div > div:nth-child(2) > table > tbody > tr > td:nth-child(1) {
  width: 55%;
}


.loyaltyTable > div > div:nth-child(3) > div:first-child > select{
  background-color: #f8f8f8;
}

.loyaltyTable > div > div:nth-child(2) {
  padding: 15px 5px;
}

.checkbox-containerAdressAdd {
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  padding-left: 10px;
  cursor: pointer;
  font-size: 22px;
  background-color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/* Hide the browser's default checkbox */
.checkbox-containerAdressAdd input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarkAdressAdd {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #e4e4e4;
}

/* On mouse-over, add a grey background color */
.checkbox-containerAdressAdd:hover input ~ .checkmarkAdressAdd {
  background-color: #ccc;
}


.checkbox-containerAdressAdd input:checked ~ .checkmarkAdressAdd {
  background-color: #009fe3;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkAdressAdd:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-containerAdressAdd input:checked ~ .checkmarkAdressAdd:after {
  display: block;
}
  
  /* Style the checkmark/indicator */
.checkbox-containerAdressAdd .checkmarkAdressAdd:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid rgb(255, 255, 255);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg) scale(1.3);
}






@media only screen and (max-width: 1500px) {

  ul.nav.nav-tabs li {
    flex: 0 1 auto; /* Verdeel tabs gelijkmatig over de rij */
  }

  .overviewInputBoxResponsive {
    display: flex;
    flex-direction: column !important;
  }

  .overviewInputResponsive {
    width: 100%;
  }

  .overviewLabelResponsive {
    padding-left: 0px !important;
  }
  .fontSize15 {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1399px) {
.customerInfoBox {
  width: 100% !important;
  height: fit-content !important;
}
}


