/* .containerTable {
  height: 100vh;
  width: 100vw;
  background-color: blue;
} */

.table-drag {
  width: 81vw;
  border-collapse: collapse;
}

.table-drag th,
.table-drag td {
  padding: 8px;
  border: 1px solid #ccc;
}

.searchIconTabledrag {
 display: flex !important;
 position: relative !important;
 top: 37% !important;
}

.table-drag th {
  background-color: #fafafa;
  width: 14.5vw;
  font-weight: bold;
  text-align: left;
  line-height: 30px;
}

.table-drag th:first-child,
.table-drag td:first-child {
  background-color: #ffffff;
  width: 15px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
}

.table-drag th:nth-last-child(1),
.table-drag th:nth-last-child(2),
.table-drag td:nth-last-child(1),
.table-drag td:nth-last-child(2) {
  width: 10px !important;
  box-sizing: border-box;
  text-align: center;
  padding-bottom: 8px;
}

.table-drag td {
  width: 18vw; /* Gebruik width in plaats van min-width */
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
}

.table-drag td:first-child {
  width: 15px;
  box-sizing: border-box;
}

.table-drag td:last-child, .table-drag td:nth-child(6) {
  width: 50px !important;
  box-sizing: border-box;
  text-align: center;
  padding-bottom: 8px;
}

.table-drag > thead > tr > th {
  background-color: white !important;
  border-bottom: 2px solid rgb(189, 189, 189);
  font-weight: bold !important;
  text-align: center;
  color: #9d9d9d ;
  border: none;
} 

.table-drag > tbody > tr {
  font-size: 15px;
  font-weight: 500;
  /* border-bottom: 1px solid #ccc !important; */
}

.table-drag > tbody > tr > td {
  border: none;
  text-align: center;
}

.table-drag > thead > tr > th > .checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  bottom: 15px;
  left: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.table-drag > tbody > tr > td > .checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  bottom: 2px !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

 .table-drag > thead > tr > th > .checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  bottom: 12px !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #c9c9c9;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  top: 3px;
  width: 5px;
  height: 10px;
  left: 7px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg) scale(1.3);
}

