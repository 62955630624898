.mainCat {
 display: flex;
 flex-direction: row;
}

.checkboxMainCat {
  position: relative;
  top: 5px;
  height: 20px;
  width: 20px;
  border-radius: 10px !important;
}

.labelMainCat {
  position: relative;
  top: 2px !important;
}

.categorieEditResponsive {
  width: 95%;
}


