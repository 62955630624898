.categoryTable {
  width: 100%;
}

.categoryTable > div > .tableBackground > .table-drag td {
  width: 36.5vw;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.categoryTable > div > .tableBackground > .table-drag td:first-child {
  width: 0.1vw;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.containerAccordion {
  background-color: transparent !important;
  border-bottom: 0;
}
.tablePresetDataRow:hover {
  background-color: transparent !important;
}
.catHeaderCheckbox .checkbox-container {
  bottom: 0;
}
.containerAccordion .panel {
  transition: 0s;
}
img.bluefiltered {
  filter: sepia(100%) hue-rotate(190deg) saturate(500%);

}
.hoverPointer:hover {
  cursor: default
}
.editCategoryImage {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.editCategoryImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.smallborder {
  border-bottom: 1px solid #dee2e6;
}