.navbar {
  position: fixed !important;
  width: calc(100% - 277px);
  z-index: 2 !important;
  height: 136px;
  left: 270px;
  background-color: #e2eefb;
  /* transition: all 0.5s; */
}

.back-button-img {
  width: 25px;
  margin-right: 10px;
}

.back-button-img:hover {
  cursor: pointer;
}

.navbarSmall {
  width: calc(100% - 107px) !important;
  left: 100px;
}

.tableLogs tr > th:nth-child(2) {
  width: 50px;
}

.warehouseBox {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #79b893;
}

.searchBox {
  position: relative;
  bottom: 7px;
}

.warehouseIcon {
  width: 60%;
  height: 60%;
  filter: brightness(0) invert(1);
}

.searchbar {
  border: none;
}

.searchbarNav::placeholder {
  border: none;
  font-weight: 500;
  color: #000000;
}

.searchbar:focus {
  outline: none;
}

.iconHeightNav {
  padding: 0 !important;
}

.iconsNav {
  width: 28px;
  height: 28px;
}

.profile-picture {
  width: 60px;
  height: 60px;
  position: relative;
  bottom: 6px;
  right: 3px;
  /* overflow: hidden; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dot {
  position: relative;
}

.dot::after {
  content: '';
  position: absolute;
  top: 3px;
  right: -2px;
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
}

.mailIconColorInvert {
  filter: invert(1);
}

.searchbarNav {
  width: 200px;
  height: 50px;
  font-size: larger;
}

.indicatorIcons {
  width: 140px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.searchIcon {
  display: flex;
  align-self: center;
  width: 23px;
  height: 23px;
}

.searchbarNav::placeholder {
  color: #000000;
}

.searchbarNav.show {
  width: 100% !important;
}

.searchbarNav:focus {
  outline: none;
}

.searchBox-expanded {
  width: 130px;
  display: flex;
}

.logOutIcon {
  position: relative;
  top: 5px;
  margin-right: 5px;
}

@media only screen and (max-width: 1112px) {
  body .collapsed .navbar {
    width: calc(100% - 270px) !important;
    left: 100px !important;
  }
}

@media only screen and (max-width: 1040px) {
  .indicatorIcons {
    display: none;
  }

  .iconsNavDrop {
    width: 20px;
    height: 20px;
  }

  .indicatorIconsDrop {
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 10px;
  }

  .searchbarNav.show {
    width: 100px !important;
  }

  .searchIcon {
    margin-right: 0px !important;
    padding-right: 0px !important;
  }

  .iconsNav {
    position: relative;
  }
}

@media only screen and (max-width: 630px) {
  .navbar {
    width: 93% !important;
    left: 10% !important;
  }
  .pageTitle {
    display: none !important;
  }
}

@media only screen and (max-width: 550px) {
  .navbar {
    padding-left: 20px !important;
    margin-left: 2px;
  }

  .usernameText {
    display: none;
  }
}

@media only screen and (max-width: 488px) {
  .searchbarNav {
    border: none;
    width: 6vw;
    position: relative;
    display: none;
  }

  .navbar {
    width: 90% !important;
    left: 12% !important;
    padding-left: 15px !important;
  }

  .userName {
    display: none;
  }

  .logOutText {
    display: none;
  }

  .logOut {
    position: absolute;
    top: 276px !important;
  }

  .dropdown-content {
    width: 200px !important;
  }

  .line {
    width: 200px !important;
  }
}

@media only screen and (max-width: 360px) {
  .iconBox {
    margin-right: 20px !important;
  }

  .navbar {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdownNav {
  position: relative;
  display: inline-block;
  height: 60px;
  border-left: 1px solid #000000;
}

.userInfoMenu {
  position: relative !important;
  top: 13px;
  left: 5px;
  z-index: 999 !important;
}

.profile-container {
  /* display: flex; */
  /* align-items: center; */
}

.link {
  text-decoration: none;
  color: gray;
}

.link:hover {
  text-decoration: none;
  color: rgb(99, 99, 99);
}

.logOut {
  color: #414141;
  position: absolute;
  top: 272px;
  right: 20px;
  text-decoration: none;
}

.logOut:hover {
  color: #888888;
  cursor: pointer;
  text-decoration: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 15px;
  bottom: -235px;
  right: -15px;
  height: 300px;
  width: auto;
  z-index: 998 !important;
  background-color: white;
  box-shadow: 3px 3px 5px 1px rgb(180, 180, 180);
  transition: opacity 0.5s;
}

.menuListedItems {
  position: relative;
  top: 75px;
}

.navbarDropDownItem {
  cursor: pointer;
  padding: 10px;
  border-top: 1px solid rgb(232, 232, 232);
}

.navbarDropDownItem:last-child {
  border-bottom: 1px solid rgb(232, 232, 232);
}

.navbarDropDownItem:hover {
  background-color: rgb(232, 232, 232);
}

.userInfoMenu ~ .dropdown-content,
.dropdown-content:hover {
  display: flex;
  flex-direction: column;
}

.dropdownIndicator {
  position: relative;
  top: 8px;
  left: 5px;
}

.dropdownNav.clicked .dropdownIndicator {
  animation: spin 0.5s forwards;
}

.dropdownNav:not(.clicked) .dropdownIndicator {
  animation: spin-back 0.5s forwards;
}

.hover-area {
  display: none;
  position: absolute;
  height: 50px;
  width: 100%;
  top: -50px;
  left: 0;
  z-index: 997;
}

.userInfoMenu:hover ~ .hover-area {
  display: block;
}

.line {
  width: 100%;
  position: absolute;
  right: 0px;
  top: 270px;
  padding-left: 5vw !important;
  border-bottom: 1px solid #d4d4d4;
}

.dropdownListedItems {
  position: relative;
  top: 80px;
}

.links {
  text-decoration: none;
  margin-bottom: 25px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes spin-back {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
