.editIconBlueprintName {  
  display: flex;
  align-self: center;
}

.editBlueprint > div > div > .table-drag td {
  width: 36.5vw;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}

.editBlueprint {
  width: 100%;
}

.editBlueprint > div > div > .table-drag td:first-child {
  width: 0.1vw;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}

@media only screen and (max-width: 1230px) {
  .editBlueprint {
    width: fit-content;
  }

  .editBlueprintOverview {
    overflow-x: scroll;
  }
}
