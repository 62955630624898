.parent {
  z-index: 10;
  background-image: linear-gradient(137deg, #0586D3 0%, #0C1217 115%);
  height: 100vh;
  width: 100vw;
  z-index: 10;
}

.errorScreenBtn {
  width: 180px;
}