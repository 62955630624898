.mediaUploader {
  overflow: hidden;
  border: 2px dashed #00000044;
  padding: 1%;
  width: 100%;
  user-select: none;
  position: relative;
  min-height: 200px;
}

.objectWithinMU {
  position: relative;
  max-width: inherit;
  max-height: fit-content;
  display:inline;
}

.colorSelectForObjectMU {
  text-align: center;
  max-width: inherit;
  border: 2px #EFEFEF solid;
  border-radius: 50px;
  transition: all 0.3s ease;
}

.colorSelectForObjectMU#error {
  border: 2px #ff0000 solid !important;
  color: #ff0000;
}

.colorSelectForObjectMU:focus {
  border: 2px #000000 solid;
}

.colorSelectForObjectMU#required {
  border: 2px #ff5d5d solid !important;
}

.colorSelectForObjectMU::-ms-expand {
  display: none;
}

.deleteImageButtonMU {
  height: 30px;
  width: 30px;
  text-decoration: none;
  border: none;
  color: #EFEFEF;
  background-color: #015a7a;/* to hide standard background for button*/
  align-items: center;
  font-weight: bold;
  display:flex;
  justify-content: center;
  font-size: 20px;
  margin: 5px !important;
  top: 0;
  right: 0;
  border-radius: 5px;
  transition: all 0.3s ease;
}

/* .deleteImageButtonMU:hover {
  background-color: #ff0000;
  color: #EFEFEF;
} */

.imageInformationContainerMU {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageNameInObjectMU {
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 10;
}

.imageNameInObjectMU#imageSize {
  font-size: 35px;
  font-weight: bold;
  color: #000000; /* Text color */
}

.imageNameInObjectMU#imageName {
  font-size: 20px;
  font-weight: normal;
  color: #000000; /* Text color */
}

.imageBoxMU {
  user-select: none;
  position:relative;
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  display: flex;
  align-items: center; 
  border-radius: 0px;
  justify-content: center;
  margin-bottom: 10px;
}

.errorImageBoxMU {
  border: 5px #ff0000 solid;
  box-shadow: #ff0000;
}

.imageBoxMU:hover {
  cursor: move;
}

.imageBoxMU::after {
  content:'';
  padding-top: 100%;
  display: block;
}

.imageInObjectMU {
  opacity: 50%;
  user-select: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit:contain; 
}

.textForTheMU {
  text-align: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.521);
  font-size: 21px;
}

.XErrorUpload{
  background-color: #f14242;
  border: solid 1px;
  width: 8vw;
  height: 8vh;
  align-items: center; 
  justify-content: center;
  display: flex;
  color:#FFFFFF;
  border-radius: 50%;
}

.mediaUploader:hover {
  cursor: pointer;
}

.uploadScreenMU {
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
}

.uploadScreenContainerMU {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
}

.uploadScreenTextMU {
  font-size: 30px;
  text-align: center;
}

.uploadScreenSubTextMU {
  font-size: 20px;
}


@media only screen and (max-width: 1300px) {
  .mediaUploader {
    width: 100%;
  }
}